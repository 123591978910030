import { Button } from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import { format as formatRut } from 'rut.js';

import {
  Student,
  StudentForm,
} from '../../../../../../types/tuitionProcessOnSite';
import { getDate } from '../../../../../../utils/dates';
import { useActionTuitionForm } from '../../hooks/useActionTuitionForm';
import { useGoTo } from '../../hooks/useGoTo';
import TuitionProcessFormFields from '../../parts/TuitionProcessFormFields';
import TuitionProcessFormHeader from '../../parts/TuitionProcessFormHeader';
import { useActionForm } from '../../parts/useActionForm';
import SearchStudentOnSiteMultiTuitionTable from './SearchStudentOnSiteMultiTuitionTable';

interface SearchStudentOnSiteMultiTuitionProps {
  students: Student[];
  showPassport: boolean;
  preload: () => void;
}

const prefix = 'tuitionProcess.searchStudent.table';

export const SearchStudentOnSiteMultiTuition = ({
  showPassport,
  students,
  preload,
}: SearchStudentOnSiteMultiTuitionProps) => {
  const student = students[0];

  const { t } = useTranslation();
  const { getActionForm, submitLoad, setSubmitLoad } = useActionForm(
    student.admissionTypeId,
  );
  const { goToPostulation } = useGoTo({ student });
  const {
    createNewPostulant,
    createNewPostulationDetail,
    updatePostulant,
    loading,
  } = useActionTuitionForm({
    student,
    goToPostulation,
    setSubmitLoad,
  });

  const whatIsCurrentStatus = useMemo(
    () =>
      student.newAdmission
        ? student.newAdmission.postulationDetailStatus
        : student.status,
    [student.newAdmission, student.status],
  );

  const actionForm = getActionForm(whatIsCurrentStatus);

  const defaultValues = Object.assign({
    rut: formatRut(student.rut),
    passportNumber: student.passportNumber,
    country: student.country,
    names: student.names,
    lastnamePather: student.lastnamePather,
    lastnameMother: student.lastnameMother,
    email: student.email,
    phone: student.phone,
    birthdate: student.birthdate && getDate(student.birthdate),
  });

  const methods = useForm<StudentForm>({
    mode: 'onSubmit',
    defaultValues,
  });
  const { handleSubmit } = methods;

  const onSubmit = useCallback(
    async (values: StudentForm, e: any) => {
      const btnValue: string = e?.nativeEvent?.submitter?.value;
      // Si existe el id del postulante se modifica la
      // información y se crea un nuevo postulation detail
      if (student.postulant_id && btnValue === 'add-study-plan') {
        return createNewPostulationDetail(values, student.postulant_id);
      }

      // Si existe el id del postulante se modifica la información
      if (student.postulant_id) {
        return updatePostulant(values, student.postulant_id, btnValue);
      }

      // Si no existe se crea un postulante nuevo
      return createNewPostulant(values);
    },
    [
      createNewPostulant,
      createNewPostulationDetail,
      student.postulant_id,
      updatePostulant,
    ],
  );

  return (
    <>
      <TuitionProcessFormHeader student={student} actionForm={actionForm} />

      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TuitionProcessFormFields student={student} passport={showPassport} />

          <h2 className="fs-22 text-uppercase text-primary fw-700 mt-4 mb-4">
            {t(`${prefix}.title`)}
          </h2>

          <Row className="mb-3">
            <Col md={8}>
              <p className="fs-16">{t(`${prefix}.subtitle`)}</p>
            </Col>
            <Col md={4}>
              <Button
                className="g-add-button"
                type="submit"
                text={t(`${prefix}.actions.addStudyPlan`)}
                size="sm"
                icon="plus"
                fullwidth
                outlined={true}
                loading={loading}
                value={'add-study-plan'}
                // disabled={!isAuthorizedTo([PermissionName.CREATE_TUITIONS_PERIOD])}
              />
            </Col>
          </Row>
          <SearchStudentOnSiteMultiTuitionTable
            data={students}
            preload={preload}
            isLoadingResults={submitLoad}
          />
        </Form>
      </FormProvider>
    </>
  );
};
