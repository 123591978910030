import { addToast } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from 'reactstrap';
import { format as formatRut } from 'rut.js';

import { useValidateRetracted } from '../../../../admission/AdmissionsListEnrolled/hooks/useValidateRetracted';
import { changeToDesist } from '../../../../api/requests/studyPlansOffer';
import { desistStudyPlanChange } from '../../../../api/requests/tuitionProcess';
import { ModalConfirmation } from '../../../../components/modals/Confirmation';
import ConfirmationModal from '../../../../components/modals/ConfirmationModal';
import { ModalsValidateRetract } from '../../../../components/modals/ModalsValidateRetract';
import { Student, StudentForm } from '../../../../types/tuitionProcessOnSite';
import { getDate } from '../../../../utils/dates';
import { showToast } from '../../../../utils/toast';
import { useActionTuitionForm } from './hooks/useActionTuitionForm';
import { useGoTo } from './hooks/useGoTo';
import TuitionProcessFormFields from './parts/TuitionProcessFormFields';
import TuitionProcessFormHeader from './parts/TuitionProcessFormHeader';
import { useActionForm } from './parts/useActionForm';

interface TuitionProcessFormProps {
  student: Student;
  passport: boolean;
  preload: () => void;
}

const prefix = 'tuitionProcess.form';

export function TuitionProcessForm({
  passport,
  student,
  preload,
}: TuitionProcessFormProps): JSX.Element {
  const { t } = useTranslation();
  const {
    getActionForm,
    modalConfirmOpen,
    setModalConfirmOpen,
    setSubmitLoad,
  } = useActionForm(student.admissionTypeId);

  const { goToPostulation } = useGoTo({ student });
  const { createNewPostulant, updatePostulant } = useActionTuitionForm({
    student,
    goToPostulation,
    setSubmitLoad,
  });

  const {
    studentName,
    selectRetractedID,
    setSelectRetractedID,
    showRetractedModal,
    setShowRetractedModal: setRetractedModal,
    validateRetracted,
  } = useValidateRetracted();

  const [confirmChangeEnrolled, setConfirmChangeEnrolled] = useState<boolean>(
    false,
  );
  const [openModalDesist, setOpenModalDesist] = useState<boolean>(false);

  const actionForm = getActionForm(
    student.newAdmission
      ? student.newAdmission.postulationDetailStatus
      : student.status,
    () =>
      student.Details_id &&
      validateRetracted(
        student.Details_id,
        student.names,
        `${student.lastnamePather} ${student.lastnameMother}`,
      ),
    () => setConfirmChangeEnrolled(true),
    () => goToPostulation({ fromStart: false }),
    () => setOpenModalDesist(true),
  );

  const defaultValues = Object.assign({
    rut: formatRut(student.rut),
    passportNumber: student.passportNumber,
    country: student.country,
    names: student.names,
    lastnamePather: student.lastnamePather,
    lastnameMother: student.lastnameMother,
    email: student.email,
    phone: student.phone,
    birthdate: student.birthdate && getDate(student.birthdate),
  });

  const methods = useForm<StudentForm>({
    mode: 'onSubmit',
    defaultValues,
  });
  const { handleSubmit } = methods;

  const onSubmit = useCallback(
    async (values: StudentForm, e: any) => {
      const btnValue: string = e?.nativeEvent?.submitter?.value;
      // Si existe el id del postulante se modifica la información
      if (student.postulant_id) {
        return updatePostulant(values, student.postulant_id, btnValue);
      }

      // Si no existe se crea un postulante nuevo
      return createNewPostulant(values);
    },
    [student.postulant_id, updatePostulant, createNewPostulant],
  );

  const onConfirmDesisted = async () => {
    setModalConfirmOpen(false);
    if (student.Details_id) {
      let { error } = await changeToDesist(student.Details_id);
      if (error) {
        showToast(true, t);
      } else {
        showToast(false, t);
        preload();
      }
    }
  };

  const onCloseDesisted = () => {
    setModalConfirmOpen(false);
  };

  const onConfirmDesist = async () => {
    if (student.newAdmission?.postulationDetailId) {
      const { data, error } = await desistStudyPlanChange(
        student.newAdmission?.postulationDetailId,
      );
      if (data) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.desistAdmissionMessage`),
        });
      }
      if (error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: t('common.errors.save'),
        });
      }
    } else {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('common.errors.save'),
      });
    }
    preload();
    onCloseModalDesist();
  };

  const onCloseModalDesist = () => {
    setOpenModalDesist(false);
  };

  return (
    <div>
      <TuitionProcessFormHeader student={student} actionForm={actionForm} />

      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TuitionProcessFormFields
            student={student}
            disabledInputs={actionForm.disabledInputs}
            passport={passport}
          />
          <div className="mt-4">{actionForm.actions}</div>
        </Form>
      </FormProvider>

      <ModalConfirmation
        open={modalConfirmOpen}
        title={t(`${prefix}.modalConfirm.title`)}
        subtitle={t(`${prefix}.modalConfirm.subtitle`)}
        onClose={onCloseDesisted}
        onConfirm={onConfirmDesisted}
      />

      <ModalsValidateRetract
        updateQuery={() => preload()}
        studentName={studentName}
        selectRetractedID={selectRetractedID}
        setSelectRetractedID={setSelectRetractedID}
        showRetractedModal={showRetractedModal}
        setRetractedModal={setRetractedModal}
      />

      <ModalConfirmation
        open={confirmChangeEnrolled}
        title={t(`${prefix}.modalConfirmChange.title`)}
        subtitle={t(`${prefix}.modalConfirmChange.subtitle`)}
        onClose={() => setConfirmChangeEnrolled(false)}
        onConfirm={() => {
          setConfirmChangeEnrolled(false);
          goToPostulation({ fromStart: true });
        }}
      />

      <ConfirmationModal
        iconName="warning"
        isOpen={openModalDesist}
        toggle={onCloseModalDesist}
        title={t(`${prefix}.modalDesist.title`)}
        body={t(`${prefix}.modalDesist.subtitle`)}
        boxDetail={{
          title: t(`${prefix}.modalDesist.detailTitle`),
          body: [student.studyPlanVersion_name ?? ''],
        }}
        primaryBtn={{
          text: t(`common.actions.confirm`),
          action: onConfirmDesist,
        }}
        secondaryBtn={{
          text: t(`common.actions.cancel`),
          action: onCloseModalDesist,
        }}
      />
    </div>
  );
}
