import {
  Button,
  OutlinedSelect,
  OutlinedSelectOptionType,
  SearchBox,
  useMobile,
} from '@octano/global-ui';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { useValidations } from '../../../../../hooks/useValidations';
import { getRegularStudentsListFilter } from '../../services/request';
import { SearchParams } from '../../types/Search';

const dictPrefix = 'changeStudyPlan.continutityList.searchControls';

const DEFAULT_VALUES = {
  search: '',
  studyPlanId: null,
};

interface FormValues {
  search: string;
  studyPlanId: OutlinedSelectOptionType | null;
}

type onSearch = {
  searchParams: SearchParams;
  page: number;
};

interface SearchControlsProps {
  onSearch?: ({ searchParams, page }: onSearch) => void;
  onClear?: () => void;
  setFilters?: (state: any) => void;
}

export function SearchControls({
  onSearch = () => null,
  onClear = () => null,
  setFilters = (state: any) => null,
}: SearchControlsProps) {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const { validateMinLength } = useValidations();

  const [studyPlans, setStudyPlans] = useState<OutlinedSelectOptionType[]>([]);

  const { handleSubmit, control, reset, watch } = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
  });

  const [searchWatch, studyPlanIdWatch] = watch(['search', 'studyPlanId']);

  useEffect(() => {
    const searchParams = {
      search: searchWatch?.trim() !== '' ? searchWatch : undefined,
      studyPlanId: studyPlanIdWatch?.value as number,
    };

    setFilters({
      ...searchParams,
      hasFilters: Object.values(searchParams).some((value) => value !== null),
    });
  }, [searchWatch, studyPlanIdWatch, setFilters]);

  const handleSearch = (values: FormValues) => {
    const searchParams = {
      search: values.search?.trim() !== '' ? values.search : undefined,
      studyPlanId: values.studyPlanId?.value as number,
      hasFilters: Object.values(values).some((value) => value !== null),
    };

    onSearch({ searchParams, page: 0 });
  };

  const handleClear = () => {
    reset(DEFAULT_VALUES);
    onClear();
    onSearch({ searchParams: {}, page: 0 });
  };

  const getFilters = useCallback(async () => {
    const { data } = await getRegularStudentsListFilter();
    if (data) {
      setStudyPlans(
        data.studyPlans.map(({ id: value, name: label }) => ({
          label,
          value,
        })),
      );
      reset();
    }
  }, [reset]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  return (
    <form
      onSubmit={handleSubmit(handleSearch)}
      style={{ gap: isMobile ? 0 : 4 }}
    >
      <Row className="pb-4 gutters-filters">
        <Col md={3}>
          <SearchBox
            name="search"
            label={t(`${dictPrefix}.nameOrId`)}
            placeholder={t(`${dictPrefix}.nameOrIdPlaceholder`)}
            control={control}
            rules={{
              validate: {
                minLength: validateMinLength(3),
              },
            }}
          />
        </Col>
        <Col md={3}>
          <OutlinedSelect
            name="studyPlanId"
            label={t(`${dictPrefix}.studyPlan`)}
            placeholder={t(`${dictPrefix}.studyPlan`)}
            control={control}
            options={studyPlans}
          />
        </Col>
        <Col md={3} />
        <Col md={3}>
          <div className="d-flex justify-content-around pt-4">
            <Button
              type="submit"
              text={t(`common.actions.search`)}
              size="md"
              fullwidth
            />
            <Button
              text={t(`common.actions.clean`)}
              className="mt-0 ml-1"
              outlined
              onClick={handleClear}
              size="md"
              fullwidth
            />
          </div>
        </Col>
      </Row>
    </form>
  );
}
