import { ColumnTable, TextOutlinedInput } from '@octano/global-ui';
import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useUserState from '../../../../hooks/useUserState';
import { PermissionName } from '../../../../types/Auth';

type TableRecord = { key: string; value: string | null };

interface Params {
  formControl: ReturnType<typeof useForm>['control'];
}

export default function useGradesEntryTableColumns({ formControl }: Params) {
  const { t } = useTranslation();
  const prefix = 'configurations.academics.notesScale';

  const { isAuthorizedTo } = useUserState();
  const isLastTableRow = useCallback((index: number, data: TableRecord[]) => {
    return data && index === data.length - 1;
  }, []);

  const COLUMNS: ColumnTable<TableRecord>[] = useMemo(
    () => [
      {
        columnName: 'key',
        headerText: t(`${prefix}.note`),
        tdClassName: ({ index, data }) => {
          return clsx(
            isLastTableRow(index, data || []) && 'text-primary bg-tertiary',
          );
        },
        cellFormat: ({ value }) => t(`${prefix}.configKeys.${value}`),
      },
      {
        columnName: 'value',
        headerText: t(`${prefix}.number`),
        tdClassName: ({ index, data }) => {
          return clsx(
            'd-flex justify-content-center align-items center',
            isLastTableRow(index, data || []) && 'text-primary bg-tertiary',
          );
        },
        cellFormat: ({ row }) => {
          return (
            <div style={{ width: 70, textAlign: 'center' }}>
              <TextOutlinedInput
                name={row.key}
                control={formControl}
                formatter={(value: string) => {
                  const MAX_ACCEPTED = 1000;
                  const parsedValue = +value;

                  if (isNaN(parsedValue)) return value.slice(0, -1);
                  if (parsedValue > MAX_ACCEPTED) return `${MAX_ACCEPTED}`;

                  return value;
                }}
                textAlign="center"
                disabled={
                  !isAuthorizedTo([PermissionName.CREATE_SETTINGS_GRADE_ENTRY])
                }
              />
            </div>
          );
        },
      },
    ],
    [t, formControl, isLastTableRow, isAuthorizedTo],
  );

  return COLUMNS;
}
