import {
  Button,
  DisplayInfo,
  addToast,
  showDialogConfirm,
} from '@octano/global-ui';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Col, Row } from 'reactstrap';
import { format as formatRut } from 'rut.js';

import {
  GetSectionsTeacherPortalByIDReponse,
  getPDFOfSectionClose,
  getSectionsTeacherPortalByIDRequest,
  postClosedSectionTeacherPortalRequest,
  postRejectClosureSectionMantainerPortalRequest,
} from '../../api/requests/MantainerPortal';
import Loading from '../../components/info/Loading';
import { PathsLayouts } from '../../config/routes';
import DetailBoxPeriodEndReview from './parts/DetailBox';
import TablePeriodEndReview from './parts/Table';

export default function PeriodEndReview() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<GetSectionsTeacherPortalByIDReponse>();
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [btnDownloadLoading, setBtnDownloadLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>();
  const history = useHistory();

  const getData = useCallback(async () => {
    setLoading(true);
    const response = await getSectionsTeacherPortalByIDRequest(id);
    if (response.error) {
      setError(response.error.code);
    } else if (response.data) {
      setError(null);
      setData(response.data);
    }
    setLoading(false);
  }, [id, setData, setLoading]);

  const back = useCallback(() => {
    history.push(PathsLayouts.closingPeriod);
  }, [history]);

  const getStudentsPending = useCallback(() => {
    return data?.students.filter((student) => !student.generalPassed) || [];
  }, [data]);

  const postData = useCallback(async () => {
    setBtnLoading(true);
    const resp = await postClosedSectionTeacherPortalRequest(Number(id));
    if (resp.error) {
      addToast({
        color: 'danger',
        icon: 'error',
        text: t(`periodEndReview.addToast.error`),
      });
    } else {
      addToast({
        color: 'success',
        icon: 'success',
        text: t(`periodEndReview.addToast.success`),
      });
      await getData();
    }
    setBtnLoading(false);
  }, [id, t, getData]);

  const downloadPDF = useCallback(async () => {
    setBtnDownloadLoading(true);
    const res = await getPDFOfSectionClose(Number(id));
    console.log({ res });
    if (res.error) {
      addToast({
        color: 'danger',
        icon: 'error',
        text: t(`periodEndReview.addToastDownload.error`),
      });
    } else if (data) {
      addToast({
        color: 'success',
        icon: 'check',
        text: t(`periodEndReview.addToastDownload.success`),
      });
      const name = `ActaDeCierre_${data.section.name}_${dayjs(
        data.section.closedAt,
      ).format('YYYY-MM-DD')}`;
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(res.data);
      a.href = url;
      a.download = `${name}.${res.data.type.split('/')[1]}`;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        a.remove();
      }, 0);
    }
    setBtnDownloadLoading(false);
  }, [id, data, t]);

  const rejectToClosed = useCallback(async () => {
    setBtnLoading(true);
    const res = await postRejectClosureSectionMantainerPortalRequest(
      Number(id),
    );
    if (res.error) {
      addToast({
        color: 'danger',
        icon: 'error',
        text: t(`periodEndReview.addToastRejectClosure.error`),
      });
    } else {
      addToast({
        color: 'success',
        icon: 'success',
        text: t(`periodEndReview.addToastRejectClosure.success`),
      });
      history.push(PathsLayouts.closingPeriod);
    }
    setBtnLoading(false);
  }, [t, id, history]);

  const onSumit = useCallback(async () => {
    const studentsPending = getStudentsPending();
    const prefix =
      studentsPending.length === 0
        ? 'notStudentsPending'
        : 'withStudentPending';
    showDialogConfirm({
      icon: {
        color: 'primary',
        name: 'warning',
      },
      title: t(`periodEndReview.modalConfirmToClose.${prefix}.title`),
      subtitle: t(`periodEndReview.modalConfirmToClose.${prefix}.subtitle`),
      children:
        studentsPending.length === 0 ? undefined : (
          <>
            <span>{t('periodEndReview.modalConfirmToClose.label')}</span>
            <ul className="box-ul-detail mb-4">
              {studentsPending.map((student) => (
                <li className="fs-16">
                  <p>
                    <span>{student.fullName} </span>
                    <span>
                      {student.run
                        ? formatRut(student.run)
                        : student.passportNumber}
                    </span>
                  </p>
                </li>
              ))}
            </ul>
          </>
        ),
      btnClose: { text: t(`common.actions.cancel`) },
      btnConfirm: {
        text: t(`common.actions.confirm`),
        onConfirm: postData,
      },
    });
  }, [postData, t, getStudentsPending]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (loading) {
    return (
      <div className="mx-3">
        <Loading insideCard />
      </div>
    );
  }

  return (
    <div className="mx-3 py-4 px-3 bg-white rounded">
      <Row>
        <Col>
          <Button
            icon={'back'}
            text={t('common.actions.back')}
            size="sm"
            outlined
            rounded
            onClick={back}
            className={'mb-4'}
          />
        </Col>
        {!error && (
          <Col md={8}>
            <DetailBoxPeriodEndReview
              data={
                data?.section as GetSectionsTeacherPortalByIDReponse['section']
              }
            />
          </Col>
        )}
      </Row>
      <h2 className="text-primary fs-20 fw-700 mt-4 mb-2 text-uppercase">
        {t(`periodEndReview.title`)}
      </h2>
      <p className="fs-16 mt-3 mb-4">{t('periodEndReview.subtitle')}</p>
      {error ? (
        <DisplayInfo
          insideCard
          maxWidth="700px"
          iconName="information"
          title={t('periodEndReview.errorResult.title')}
          textBody={t('periodEndReview.errorResult.subtitle')}
        />
      ) : (
        <>
          {data?.section.closedAt && (
            <Row className="mb-4">
              <Col md={9} />
              <Col>
                <Button
                  icon="download"
                  text={t('periodEndReview.buttonDownload')}
                  fullwidth
                  outlined
                  onClick={downloadPDF}
                  loading={btnDownloadLoading}
                />
              </Col>
            </Row>
          )}
          <TablePeriodEndReview
            data={
              data?.students as GetSectionsTeacherPortalByIDReponse['students']
            }
            passingGrade={
              data?.passingGrade as GetSectionsTeacherPortalByIDReponse['passingGrade']
            }
            section={
              data?.section as GetSectionsTeacherPortalByIDReponse['section']
            }
          />
        </>
      )}
      <br />
      {data?.section.closedAt && (
        <Row>
          <Col md={9} />
          <Col>
            <Button
              text={t('periodEndReview.bottonRejectClose')}
              fullwidth
              onClick={rejectToClosed}
              loading={btnLoading}
            />
          </Col>
        </Row>
      )}
      {data && !data?.section.closedAt && (
        <Row>
          <Col md={9} />
          <Col>
            <Button
              text={t('periodEndReview.buttonConfirmToClose')}
              fullwidth
              onClick={onSumit}
              loading={btnLoading}
            />
          </Col>
        </Row>
      )}
    </div>
  );
}
