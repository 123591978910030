import { useMemo } from 'react';
import { Card, Container } from 'reactstrap';

import TabControl from '../../../components/tabs/TabControl';
import { PathsLayouts } from '../../../config/routes';
import { PermissionName } from '../../../types/Auth';
import CampusOrScheduleList from './EnrollmentSoftChangeList';
import CampusOrScheduleListContinuity from './EnrollmentSoftChangeListContinuity';

export default function ChangeStudyPlanList() {
  const tabsPrefix = 'changeStudyPlan';

  const tabs = useMemo(
    () => [
      {
        name: 'list',
        content: <CampusOrScheduleList />,
      },
      {
        name: 'list-continuity',
        content: <CampusOrScheduleListContinuity />,
        permissions: [PermissionName.EDIT_CHANGE_CONTINUITY_STUDENTY_PLAN],
      },
    ],
    [],
  );

  return (
    <Container fluid>
      <Card className="">
        <TabControl
          tabsPrefix={`${tabsPrefix}.tabs`}
          tabs={tabs}
          path={PathsLayouts.enrollmentSoftChange}
        />
      </Card>
    </Container>
  );
}
