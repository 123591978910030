export interface Resolution {
  id?: number;
  resolutionNumber: string;
  fileName: string | null;
  file: any;
}

export interface AcademicOfferViewTableInfoResponse {
  data: SchoolDate[];
  academicOfferResume: AcademicOfferResume;
  total: number;
  total_pages: number;
}

export interface SchoolDate {
  School_id: number;
  School_code: string;
  School_name: string;
  School_description?: string;
  School_createdAt: string;
  School_updatedAt: string;
  quota: number;
  enrolled: number;
  duplicatedQuota: number;
}

export interface AcademicOfferResume {
  AcademicOffer_id: number;
  AcademicOffer_periodId: number;
  AcademicOffer_duplicatedAcademicOfferId: any;
  AcademicOffer_quota: number;
  AcademicOffer_status: string;
  AcademicOffer_createdAt: string;
  AcademicOffer_updatedAt: string;
  enrolled: number;
  duplicatedQuota: number;
  AcademicOfferStudyPlanVersionOffers_count: number;
}

export interface AcademicOfferViewTableInfoQuery {
  page: number;
}

export enum GeneralEventType {
  TUITION_INITIAL = 1,
  ACADEMIC_PERIOD,
  COURSE_ENROLLMENT,
  REGULARITY,
  HOLIDAYS,
  TUITION_CONTINUITY,
  EARLY_RETRACTION,
  TUITION_INITIAL_WITHOUT_SUA,
}

export interface Period {
  active: boolean;
  code: string;
  endDate: string;
  id: number;
  name: string;
  startDate: string;
  academicCalendars?: {
    id: number;
    eventCalendar: { id: number; generalType?: { id: GeneralEventType } };
  }[];
}

export interface VacantOfferFormDataResponse {
  periods: Period[];
}
