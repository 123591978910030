import { ColumnTable } from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { Input } from 'reactstrap';
import { Campus, RolePermission } from '../../../../types/Roles';
import { usePermissionsLoader } from '../parts/PermissionsLoader';
import { PermissionTableRow } from '../parts/PermissionTable';
import { usePermissionState } from './userPermissionState';

export default function usePermissionsColumns() {
  const { data } = usePermissionsLoader();
  const { setPermissionState } = usePermissionState();

  const printDoubleHeader = useCallback(
    (columns: ColumnTable<PermissionTableRow>[]) => {
      const filterColumns = (keyword: string) =>
        columns.filter((column) => column.columnName.includes(keyword));

      const campusesColumns = filterColumns('campus-');
      const schoolsColumns = filterColumns('school-');

      const renderColumns = (
        columnsToRender: ColumnTable<PermissionTableRow>[],
      ) =>
        columnsToRender.map((column) => (
          <th
            className={column.thClassName}
            key={column.columnName}
            rowSpan={3}
          >
            {column.headerText}
          </th>
        ));

      return (
        <>
          <tr className="text-center">
            <th style={{ border: '0', borderColor: '#dee2e6' }} rowSpan={2}>
              Permiso
            </th>
            <th rowSpan={2}>Institucional</th>
            {campusesColumns.length > 0 && (
              <th
                style={{ border: '0', borderColor: '#dee2e6' }}
                colSpan={campusesColumns.length}
              >
                Sede
              </th>
            )}
            {schoolsColumns.length > 0 && (
              <th colSpan={schoolsColumns.length}>Escuela</th>
            )}
          </tr>
          <tr className="table-borderless">
            {renderColumns([...campusesColumns, ...schoolsColumns])}
          </tr>
        </>
      );
    },
    [],
  );

  const modifyPermissionRowInstitutional = useCallback(
    (campusesAll: boolean, row: PermissionTableRow) => {
      setPermissionState((prevPermissionState) => {
        const rolePermissionsIndex = prevPermissionState.findIndex(
          (currentPermission) => currentPermission.permission?.key === row.key,
        );
        if (rolePermissionsIndex !== -1) {
          const rolePermission = prevPermissionState[rolePermissionsIndex];
          const updatedRolePermission: Partial<RolePermission> = {
            ...rolePermission,
            campusesAll: campusesAll,
            campuses: campusesAll ? [] : rolePermission.campuses,
          };
          const updatedPermissionState = [...prevPermissionState];
          updatedPermissionState[rolePermissionsIndex] = updatedRolePermission;
          return updatedPermissionState;
        }

        const newRolePermission: Partial<RolePermission> = {
          campusesAll: campusesAll,
          permission: { key: row.key, name: row.name },
        };
        return [...prevPermissionState, newRolePermission];
      });
    },
    [setPermissionState],
  );

  const modifyPermissionRow = useCallback(
    (campusId: number, row: PermissionTableRow) => {
      setPermissionState((prevPermissionState) => {
        const rolePermissionsIndex = prevPermissionState.findIndex(
          (currentPermission) => currentPermission.permission?.key === row.key,
        );

        if (rolePermissionsIndex !== -1) {
          const rolePermission = prevPermissionState[rolePermissionsIndex];
          const campuses = rolePermission.campuses ?? [];
          const campusIndex = campuses.findIndex(
            (currentCampus) => currentCampus.id === campusId,
          );
          let updatedCampuses: Campus[] = [];
          if (campusIndex !== -1) {
            updatedCampuses = campuses.filter(
              (currentCampus) => currentCampus.id !== campusId,
            );
          } else {
            updatedCampuses = [...campuses, { id: campusId }];
          }

          const updatedRolePermission = {
            ...rolePermission,
            campuses: updatedCampuses,
            campusesAll: row.campusesAll,
            permission: { key: row.key, name: row.name },
          };

          const updatedPermissionState = [...prevPermissionState];
          updatedPermissionState[rolePermissionsIndex] = updatedRolePermission;

          // Eliminar el permiso si no tiene campuses
          if (updatedRolePermission.campuses.length === 0) {
            return updatedPermissionState.filter(
              (_, index) => index !== rolePermissionsIndex,
            );
          }

          return updatedPermissionState;
        } else {
          return [
            ...prevPermissionState,
            {
              campusesAll: row.campusesAll,
              permission: { key: row.key, name: row.name },
              campuses: [{ id: campusId }],
            },
          ];
        }
      });
    },
    [setPermissionState],
  );

  const generateColumsFromCampuses = useCallback(() => {
    return (
      data?.campuses?.map<ColumnTable<PermissionTableRow>>((c) => {
        return {
          columnName: `campus-${c.id}`,
          headerText: c.name,
          thClassName: 'text-center column-sticky bg-secondary campus',
          tdClassName: 'text-center column-sticky bg-white',
          width: '200px',
          cellFormat: (options) => {
            return (
              <>
                <div className="form-group">
                  <label className="g-checkbox-input-container">
                    <Input
                      className="g-checkbox-input"
                      type="checkbox"
                      disabled={
                        options.row.isInstitutional || options.row.campusesAll
                      }
                      defaultChecked={options.value}
                      onChange={() => modifyPermissionRow(c.id, options.row)}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <small className="g-input-error form-text text-danger"></small>
                </div>
              </>
            );
          },
        };
      }) ?? []
    );
  }, [data?.campuses, modifyPermissionRow]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const generateColumsFromSchools = useCallback(() => {
    return (
      data?.schools.map<ColumnTable<PermissionTableRow>>((s) => {
        return {
          columnName: `school-${s.id}`,
          headerText: s.name,
          thClassName: 'text-center column-sticky bg-secondary school',
          tdClassName: 'text-center column-sticky bg-white',
          width: '200px',
          cellFormat: (options) => {
            return (
              <div className="form-group">
                <label className="g-checkbox-input-container">
                  <Input
                    className="g-checkbox-input"
                    type="checkbox"
                    id=""
                    name=""
                    defaultChecked={options.value}
                    onChange={() => console.log(options)}
                  />
                  <span className="checkmark"></span>
                </label>
                <small className="g-input-error form-text text-danger"></small>
              </div>
            );
          },
        };
      }) ?? []
    );
  }, [data?.schools]);

  const columns = useMemo<ColumnTable<PermissionTableRow>[]>(() => {
    return [
      {
        columnName: 'name',
        headerText: 'Permiso',
        thClassName: 'text-center column-sticky bg-secondary',
        tdClassName: 'text-center column-sticky bg-white',
        width: '200px',
      },
      {
        columnName: 'campusesAll',
        headerText: 'Institucional',
        thClassName: 'text-center column-sticky bg-secondary',
        tdClassName: 'text-center column-sticky bg-white',
        width: '200px',
        cellFormat: (options) => {
          return (
            <div className="form-group">
              <label className="g-checkbox-input-container">
                <Input
                  className="g-checkbox-input"
                  type="checkbox"
                  id=""
                  name=""
                  checked={options.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    modifyPermissionRowInstitutional(
                      e.target.checked,
                      options.row,
                    )
                  }
                />
                <span className="checkmark"></span>
              </label>
              <small className="g-input-error form-text text-danger"></small>
            </div>
          );
        },
      },
      ...generateColumsFromCampuses(),
      // ...generateColumsFromSchools(),
    ];
  }, [generateColumsFromCampuses, modifyPermissionRowInstitutional]);

  return {
    columns,
    printDoubleHeader,
  };
}
