import { PermissionName } from '../../types/Auth';
import { CampusBody } from '../../types/CampusTypes';
import { BASE } from '../endpoints';
import request from '../request';

export interface CampusListResponse {
  data: CampusBody;
}

export function getBase() {
  const url = BASE.BASE;
  return request<{
    id: number;
    email: string;
    fullname: string;
    isActive: boolean;
    permissions: PermissionName[];
  }>(url);
}
