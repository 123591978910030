import { Dialog } from '@octano/global-ui';
import { Fragment } from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import AppVersion from './components/version/AppVersion';
import { AUTHORIZED_ROUTES, NOT_AUTHORIZED_ROUTES } from './config/routes';
import useUserState from './hooks/useUserState';
import ParametersProvider from './providers/ParametersProvider';

function App() {
  const { userData, isAuthorizedTo } = useUserState();

  let routeEntries = Object.entries(
    userData ? AUTHORIZED_ROUTES : NOT_AUTHORIZED_ROUTES,
  );

  routeEntries = routeEntries.filter(([_, value]) => {
    if (value.disabled) {
      return false;
    }
    if (!value.requiredPermissions?.length) {
      return true;
    }

    return isAuthorizedTo(
      value?.requiredPermissions || [],
      value.allPermissionsRequired,
    );
  });

  const ParametersLoader = userData ? ParametersProvider : Fragment;

  return (
    <>
      <AppVersion />
      <Router>
        <ParametersLoader>
          <Switch>
            {routeEntries.map(([key, value]) => {
              const Layout = value.layoutComponent;
              return <Route key={key} path={key} component={Layout} />;
            })}
            <Redirect to={routeEntries[0][0]} />
          </Switch>
        </ParametersLoader>
      </Router>
      <Dialog />
    </>
  );
}

export default App;
