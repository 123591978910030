import { Table } from '@octano/global-ui';
import React, { useMemo } from 'react';
import { PermissionWithMetadata } from '../../../../types/Roles';
import usePermissionsColumns from '../hooks/usePermissionsColumn';
import { usePermissionState } from '../hooks/userPermissionState';

export interface PermissionTableRow {
  key: string;
  name: string;
  group: string;
  campusesAll: boolean;
  isInstitutional: boolean; //el scope
  [key: string]: boolean | any; //campus-1, campus-2, ...,campus-n
}

function PermissionTable(props: { permissions: PermissionWithMetadata[] }) {
  const { permissionState } = usePermissionState();

  /**
   * Permisos de un grupo con checks seleccionados recuperados
   * desde la info d el rol.
   * La data de la tabla está sincronizado con el hook usePermissionState
   * Ese Hook almacena los datos de los permisos que vienen del backend
   * y los nunevos que se van seleccionando.
   * usePermissionState crea o actualiza los permisos.
   */
  const permissions = useMemo<PermissionTableRow[]>(() => {
    const rolePermissionsMap = new Map(
      permissionState.map((rp) => [rp.permission?.key, rp]),
    );

    /** props.permissions grupo de permisos que vienen del loader de permisos en la vista padre. */
    return props.permissions.map((p) => {
      const isInstitutional = !p.hasOwnProperty('scopes');
      const rolePermission = rolePermissionsMap.get(p.key);
      return {
        key: p.key,
        name: p.name,
        group: p.group,
        isInstitutional,
        campusesAll: Boolean(rolePermission?.campusesAll),
        ...rolePermission?.campuses?.reduce(
          (acc: Record<string, boolean>, c) => {
            acc[`campus-${c.id}`] = true;
            return acc;
          },
          {},
        ),
      };
    });
  }, [props.permissions, permissionState]);

  /**
   * Hook que genera las columnas de forma dinámica
   */
  const { columns, printDoubleHeader } = usePermissionsColumns();

  /**
   * Genera los Header custom segun las columnas agregadas en el hook usePermissionsColumns
   */
  return (
    <Table
      noDefaultHead={true}
      headComponent={({ columns }) => printDoubleHeader(columns)}
      height={700}
      columns={columns}
      data={permissions}
      striped={false}
      borderless={false}
      isLoadingResults={false}
    />
  );
}

export default PermissionTable;
