export interface Account {
  id: number;
  email: string;
  fullname: string;
  isActive: boolean;
}

export interface Permission {
  id: number;
  name: string;
  key: PermissionName;
}

export interface Access {
  token: string;
}

export interface Refresh {}

export interface AuthResponse {
  account: Account;
  permissions: Permission[];
  access: Access;
  refresh: Refresh;
}

export enum PermissionName {
  CREATE_PERIOD = 'create.period',
  SECTIONS_PACKAGES_ALL = 'sections-packages.all',
  SECTION_PACKAGES_LOAD_ALL = 'section-packages-load.all',
  ENROLLMENT_MODIFICATION = 'enrollment-modification.all',
  SHOW_REPORTS = 'show.report',
  UPLOAD_STUDENTS_EMAILS = 'upload.file.no.email',
  DOWNLOAD_STUDENTS_WITHOUT_EMAIL = 'download.file.no.email',
  DOWNLOAD_SECTION_GRADES = 'download.file.section.grades',
  DOWNLOAD_TUITIONS_PERIOD_CONSOLIDATED = 'download.file.tuition.period-consolidated',
  DOWNLOAD_REPORT_ACADEMIC = 'download.file.academic',
  DOWNLOAD_STUDENT_SECTION_ENROLLED = 'download.file.student.section.enroll',
  DOWNLOAD_STUDENT_PROGRESS = 'download.file.student.progress',
  SHOW_SECTION_ATTRIBUTES_LIST = 'show.section-attributes-list',
  GET_SECTION_ATTRIBUTES = 'get.section-attributes',
  DOWNLOAD_SECTION_ATTRIBUTES_BASE_INFO = 'download.section-attributes-base-info',
  SEARCH_TEACHER = 'search.teacher',
  TEACHERS_ACTIVATION_ALL = 'teachers-activation.all',
  ACTIVATE_TEACHER_ACCOUNT = 'activate.teacher',
  SECTIONS_MODIFICATION = 'modification-sections.all',
  SHOW_STUDENT_STATUS = 'show.student.status',
  CHANGE_STUDENT_STATUS = 'change.student.status',
  REPORT_STUDENT_STATUS = 'download.file.students.status',
  SHOW_LIST_PERIOD_CLOSE_ADMIN_PORTAL = 'admin-portal.period-close.list',
  SHOW_PAYMENT_HUB = 'payment-hub.show',
  UPDATE_SETTINGS_TIME_LESSON = 'settings.time-lesson.update',
  CREATE_SETTINGS_GRADE_ENTRY = 'settings.grades-entry.create',
  UPDATE_SETTINGS_FINANTIAL = 'settings.finantial.update',
  STUDENTS_POST_CLOSE = 'admin-portal.students-post-close',
  SHOW_GRADUATION_PROCESS_MASSIVE = 'graduation-process-information.show',
  LAUNCH_GRADUATION_PROCESS_MASSIVE = 'graduation-process.launch',
  SEARCH_GRADUATE_GRADUATION_PROCESS_SINGLE = 'graduation-process.find-student',
  LAUNCH_GRADUATION_PROCESS_SINGLE = 'graduation-process.launch-one',
  SHOW_GRADUATES_LIST = 'graduation-process.show-graduated',
  STUDENT_CURRICULUM_PROGRESS_LIST = 'admin-portal.student-curriculum-progress.student-list',
  STUDENT_COURSE_VALIDATION_INTERNAL_LIST = 'admin-portal.student-course-validation.student-list',
  ACADEMIC_RECORD_LOAD = 'admin-portal.academic-record-load',
  STUDENTS_PETITIONS_LIST = 'admin-portal.student-petition.list',
  STUDENTS_DEGREE_LIST = 'admin-portal.student-degree.list',
  EDIT_CHANGE_STUDENTY_PLAN = 'admin-portal.enrollment-soft-change.edit',
  EDIT_CHANGE_CONTINUITY_STUDENTY_PLAN = 'admin-portal.enrollment-soft-change-continuity.edit',
  STUDENT_MANAGEMENT_STUDENT_FILE_UPDATE = 'admin-portal.studen-management.student-file.update',
  // PERMISOS DE ESTRUCTURA ACACADEMICA
  ACADEMIC_STRUCTURE = 'academic-structure.all',
  // PERMISOS DE OFERTA ACADEMICA
  ACADEMIC_OFFER_CURRICULAR_SUBJECT = 'academic-offer.curricular-subject.all',
  ACADEMIC_OFFER_CURRICULUM = 'academic-offer.curriculum.all',
  ACADEMIC_OFFER_STUDY_PLAN = 'academic-offer.study-plans.all',
  ACADEMIC_OFFER_VACANT_OFFER = 'academic-offer.vacant-offer.all',
  ACADEMIC_OFFER_INTERIM_DEGREE_LIST = 'academic-offer.interim-degree.list',
  ACADEMIC_OFFER_INTERIM_DEGREE_MANAGE = 'academic-offer.interim-degree.manage',
  // PERMISOS DE ADMISION Y MATRICULA
  ADMISSIONS_AND_ENROLLMENTS_ADMISSION_MANAGEMENT = 'admissions-and-enrollments.admission-management.all',
  ADMISSIONS_AND_ENROLLMENTS_TARIFFS_AND_DISCOUNTS = 'admissions-and-enrollments.tariffs-and-discounts.all',
  ADMISSIONS_AND_ENROLLMENTS_TUITION_PROCESS = 'admissions-and-enrollments.tuition-process.all',
  ADMISSIONS_AND_ENROLLMENTS_DOCUMENTATION_REVIEW_PROCESS = 'admissions-and-enrollments.documentation-review-process.all',
  // PERMISOS DE HERRAMIENTAS DE  GESTION
  MANAGEMENT_TOOLS_USERS_AND_ROLES = 'management-tools.users-and-roles.all',
  // PERMISOS DE GRADUACIONES DE TITULOS INTERMEDIOS
  INTERIM_DEGREE_GRADUATION_PROCESS_ALL = 'interim-degree-graduation-process.all',
  // PERMISOS DE MATRÍCULA DE CONTINUIDAD
  TUITION_CONTINUITY_TARIFF_AND_TUITION = 'tuition-continuity.tariff-and-tuition.all',
  TUITION_CONTINUITY_MANAGEMENT = 'tuition-continuity.management.all',
  TUITION_CONTINUITY_DOCUMENT_REVIEW = 'tuition-continuity.document-review.all',
}

export enum UserSessionEvent {
  EXPIRED = 'user-session-expired',
}
