import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from 'reactstrap';
import { ModalConfirmation } from '../../../../../../components/modals/Confirmation';
import { useActionAdminAccountForm } from '../../hooks/useActionAdminAccountForm';
import useGetRutOrPassport from '../../hooks/useGetRutOrPassport';
import { AdminAccountForm } from '../../types';
import CreateUserFormContactFields from './CreateUserFormContactFields';
import CreateUserFormPersonalFields from './CreateUserFormPersonalFields';
import CreateUserFormRolFields from './CreateUserFormRolFields';

interface CreateUserFormProps {
  prefix: string;
  defaultValues: AdminAccountForm;
  refresh: () => void;
}

export function CreateUserForm({
  prefix,
  defaultValues,
  refresh,
}: CreateUserFormProps): JSX.Element {
  const { t } = useTranslation();
  const { showPassport: passport } = useGetRutOrPassport();
  const {
    createAdminAccountS,
    updateAdminAccountS,
    loading,
  } = useActionAdminAccountForm({
    user: defaultValues,
    refresh,
  });

  const [modalConfirm, setModalConfirm] = useState<boolean>(false);

  const methods = useForm<AdminAccountForm>({
    mode: 'onChange',
    defaultValues: defaultValues,
  });
  const { handleSubmit } = methods;

  const onSubmit = useCallback(
    async (values: AdminAccountForm, e: any) => {
      // Si existe el id del user se modifica la información
      if (defaultValues.id) {
        return setModalConfirm(true);
      }
      // Si no existe se crea un user nuevo
      return createAdminAccountS(values);
    },
    [defaultValues?.id, createAdminAccountS],
  );

  const onClose = () => {
    setModalConfirm(false);
  };

  const onConfirm = () => {
    if (defaultValues.id) {
      const values = methods.getValues();
      updateAdminAccountS(values);
      onClose();
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <article>
          <header>
            <h2 className="fs-22 text-uppercase text-primary fw-700 mt-4 mb-4">
              {t(`${prefix}.form.personal.title`)}
            </h2>
          </header>

          <CreateUserFormPersonalFields
            prefix={`${prefix}.form`}
            passport={passport}
            disabledInputs={Boolean(defaultValues.id)}
          />
        </article>

        <article>
          <header>
            <h2 className="fs-22 text-uppercase text-primary fw-700 mt-4 mb-4">
              {t(`${prefix}.form.contact.title`)}
            </h2>
          </header>

          <CreateUserFormContactFields
            prefix={`${prefix}.form`}
            passport={passport}
            disabledInputs={Boolean(defaultValues.id)}
            isSubmitLoad={loading}
          />
        </article>

        {defaultValues.id && (
          <article>
            <header>
              <h2 className="text-primary fs-22 fw-700 mt-4 mb-2 text-uppercase">
                {t(`${prefix}.form.role.title`)}
              </h2>
              <p className="fs-16 mt-3 mb-4">{t(`${prefix}.form.role.body`)}</p>
            </header>

            <CreateUserFormRolFields
              prefix={`${prefix}.form`}
              passport={passport}
              disabledInputs={Boolean(defaultValues.id)}
              isSubmitLoad={loading}
            />

            <ModalConfirmation
              open={modalConfirm}
              title={'Asignar permisos a usuario ya existente'}
              subtitle={
                'Este usuario ya existe, si confirmas se le concederán permisos de administrador según el rol seleccionado. Confirma para continuar.'
              }
              onConfirm={onConfirm}
              onClose={onClose}
            />
          </article>
        )}
      </Form>
    </FormProvider>
  );
}
