import { Card, Col, Row } from 'reactstrap';
import { useState, useEffect, useCallback } from 'react';
import { showInterimDegree } from '../../../api/requests/academicOfferInterimDegree';
import { useTranslation } from 'react-i18next';
import { addToast } from '@octano/global-ui';
import useUserState from '../../../hooks/useUserState';
import { PermissionName } from '../../../types/Auth';
import CurriculumBox from './parts/CurriculumBox';
import { useParams } from 'react-router-dom';
import GoBackToListButton from '../../../components/buttons/GoBackToListButton';
import DisplayPermissionError from '../../../components/info/DisplayPermissionError';
import InterimDegreeForm, {
  InterimDegreeFormFields,
} from './parts/InterimDegreeForm';
import { useForm, FormProvider } from 'react-hook-form';
import { InterimDegree } from './interfaces/interimDegree.interface';

export const MODULE_PERMISSIONS = [
  PermissionName.ACADEMIC_OFFER_INTERIM_DEGREE_LIST,
];
const dictPrefix = 'interimDegree.show';

const InterimDegreeShow = () => {
  const { t } = useTranslation();
  const { isAuthorizedTo } = useUserState();

  const { interimDegreeId } = useParams<{ interimDegreeId: string }>();

  const [interimDegree, setInterimDegree] = useState<InterimDegree>();
  const [detailLoaded, setDetailLoaded] = useState<boolean>(false);

  const methods = useForm<InterimDegreeFormFields>({
    defaultValues: {
      name: '',
      description: '',
      hasntActivities: false,
      semesterCoursesIds: [],
      activities: [],
    },
  });
  const { reset } = methods;

  const handleDetails = useCallback(
    async (id: string | number) => {
      const { data, error } = await showInterimDegree(id);

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        setInterimDegree(data ?? undefined);
        reset({
          name: data?.name ?? '',
          description: data?.description ?? '',
          hasntActivities: !data?.hasActivities,
          semesterCoursesIds:
            data?.interimDegreeSemesterCourse?.map(
              (e) => e?.semesterCourseId,
            ) ?? [],
          activities: data?.interimDegreeActivities ?? [],
        });
      }
    },
    [t, reset],
  );

  useEffect(() => {
    if (!detailLoaded && interimDegreeId) {
      handleDetails(interimDegreeId);
      setDetailLoaded(true);
    }
  }, [handleDetails, detailLoaded, interimDegreeId]);

  if (!isAuthorizedTo(MODULE_PERMISSIONS, true)) {
    return (
      <div className="w-full px-3">
        <DisplayPermissionError permissions={MODULE_PERMISSIONS} insideCard />
      </div>
    );
  }

  return (
    <Card className="p-4 mx-3">
      <Row>
        <Col className="mb-4" xs={12}>
          <div className="d-flex flex-wrap w-100 align-items-start justify-content-center">
            <GoBackToListButton text={t(`${dictPrefix}.back`)} />
            <div className="flex-fill px-2" />
            <CurriculumBox
              studyPlan={
                interimDegree?.curriculum?.studyPlanVersion?.studyPlan?.name
              }
              studyPlanCode={
                interimDegree?.curriculum?.studyPlanVersion?.studyPlan?.code
              }
              version={interimDegree?.curriculum?.studyPlanVersion?.name}
              mention={
                !interimDegree?.curriculum?.isPrimary
                  ? interimDegree?.curriculum?.name
                  : '-'
              }
              school={
                interimDegree?.curriculum?.studyPlanVersion?.studyPlan?.school
                  ?.name
              }
            />
          </div>
        </Col>
      </Row>
      <FormProvider {...methods}>
        <InterimDegreeForm
          dictPrefix={dictPrefix}
          curriculum={interimDegree?.curriculum}
          disabled={true}
          hideActivities={!interimDegree?.hasActivities}
        />
      </FormProvider>
    </Card>
  );
};

export default InterimDegreeShow;
