import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';

import BaseLoader from './parts/BaseLoader';
import { BaseLoader as PeriodsLoader } from './parts/PeriodsLoader';
import SearchFilters from './parts/SearchFilters';
import StudentsLoader from './parts/StudentsLoader';
import Table from './parts/Table';

export default function CourseValidationInternal() {
  const prefix = 'courseValidationInternal';
  const { t } = useTranslation();
  const texts = useMemo(
    () => ({
      body: t(`${prefix}.body`),
    }),
    [t],
  );

  return (
    <Card className="p-4 mt-2 mx-3">
      <p className="text-medium fs-16 mt-3 mb-5">{texts.body}</p>
      <BaseLoader>
        {(studyPlanVersions) => (
          <PeriodsLoader>
            {({ data }) => (
              <StudentsLoader>
                <Row>
                  <Col xs={12}>
                    <SearchFilters
                      periods={data.periods}
                      studyPlanVersions={studyPlanVersions}
                    />
                  </Col>
                  <Col xs={12}>
                    <Table />
                  </Col>
                </Row>
              </StudentsLoader>
            )}
          </PeriodsLoader>
        )}
      </BaseLoader>
    </Card>
  );
}
