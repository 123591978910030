import { Card, Col, Row } from 'reactstrap';
import { useState, useEffect, useCallback } from 'react';
import {
  curriculumForms,
  getCurriculums,
} from '../../../api/requests/academicOfferInterimDegree';
import { Curriculum } from './interfaces/curriculum.interface';
import { useTranslation } from 'react-i18next';
import { addToast } from '@octano/global-ui';
import { InterimDegreeCurriculumsTable } from './parts/CurriculumsTable';
import useUserState from '../../../hooks/useUserState';
import { PermissionName } from '../../../types/Auth';
import DisplayPermissionError from '../../../components/info/DisplayPermissionError';
import { MODULE_PERMISSIONS as MANAGE_MODULE_PERMISSIONS } from './InterimDegrees';
import { CurriculumsSearchControls } from './parts/CurriculumsSearchControls';
import { CurriculumFilters } from './interfaces/curriculum-filters.interface';
import { CurriculumForm } from './interfaces/curriculum-form.interface';

const dictPrefix = 'interimDegree.curriculums';
const ITEMS_PER_PAGE = 10;
export const MODULE_PERMISSIONS = [
  PermissionName.ACADEMIC_OFFER_INTERIM_DEGREE_LIST,
];

const InterimDegreeCurriculums = () => {
  const { t } = useTranslation();
  const { isAuthorizedTo } = useUserState();

  const [filters, setFilters] = useState<CurriculumFilters>();
  const [tableData, setTableData] = useState<Curriculum[]>([]);
  const [isLoadingTable, setIsTableLoading] = useState<boolean>(false);

  const [form, setForm] = useState<CurriculumForm>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);

  const handleSearch = useCallback(
    async (params: { page: number } & CurriculumFilters) => {
      setIsTableLoading(true);
      const { data, error } = await getCurriculums({
        ...params,
        items: ITEMS_PER_PAGE,
      });

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        setTableData(data?.data ?? []);
        setTotalPages(data?.total_pages || 0);
        setTotalItems(data?.total || 0);
      }
      setIsTableLoading(false);
    },
    [t],
  );

  useEffect(() => {
    handleSearch({ ...filters, page: currentPage });
  }, [handleSearch, currentPage, filters]);

  useEffect(() => {
    curriculumForms().then((next) => setForm(next?.data ?? undefined));
  }, []);

  if (!isAuthorizedTo(MODULE_PERMISSIONS, true)) {
    return (
      <div className="w-full px-3">
        <DisplayPermissionError permissions={MODULE_PERMISSIONS} insideCard />
      </div>
    );
  }

  return (
    <Card className="p-4 mx-3">
      <Row>
        <Col xs={12}>
          <p className="mb-4 mt-2">{t(`${dictPrefix}.pageDescription`)}</p>
        </Col>
        <Col xs={12}>
          <CurriculumsSearchControls
            schools={form?.schools}
            studyPlans={form?.studyPlans}
            versions={form?.versions}
            mentions={form?.mentions}
            onSearch={(next) => {
              setCurrentPage(0);
              setFilters(next);
            }}
            onClear={() => {
              setCurrentPage(0);
              setFilters(undefined);
            }}
          />
        </Col>
        <Col xs={12}>
          <InterimDegreeCurriculumsTable
            data={tableData}
            itemPerPage={ITEMS_PER_PAGE}
            totalItems={totalItems}
            totalPages={totalPages}
            currentPage={currentPage}
            hasFilters={!!filters}
            manageEnabled={isAuthorizedTo(MANAGE_MODULE_PERMISSIONS)}
            isLoadingResults={isLoadingTable}
            onChangePage={(page) => {
              setCurrentPage(page);
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default InterimDegreeCurriculums;
