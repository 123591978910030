import { Button, Icon, Modal, addToast } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { useLayoutState } from '../../../../../hooks/useLayoutState';
import { deleteDiscountRequest } from '../../api';

interface DiscountFormProps {
  itemToDelete: number;
  show: boolean;
  toggleModal: () => void;
  reloadDiscountList: () => void;
}

export const DeleteDiscountModal = ({
  itemToDelete,
  show,
  toggleModal,
  reloadDiscountList,
}: DiscountFormProps) => {
  const prefix = 'tariffAndTuition.discounts';
  const { t } = useTranslation();
  const { showErrorModal } = useLayoutState();

  const onsubmit = async () => {
    const { data, error } = await deleteDiscountRequest(itemToDelete);

    if (error && error.code === 'CONNECTION') {
      showErrorModal();
      return;
    } else if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t('common.errors.save'),
      });
      toggleModal();
    }
    if (data) {
      reloadDiscountList();
      toggleModal();
      addToast({
        icon: 'success',
        color: 'success',
        text: t(`${prefix}.deleteChanges`),
      });
    }
  };

  return (
    <Modal isOpen={show} toggle={toggleModal} size="lg" unmountOnClose>
      <Row className="text-center py-4 px-3">
        <Col xs={12}>
          <Icon name="warning" color="secondary" size="65px" />
        </Col>
        <Col xs={12} className="py-4">
          <span className="fs-20 text-dark">
            {t(`${prefix}.discount.formTitleDelete`)}
          </span>
        </Col>
        <Col xs={12}>
          <p className="fs-18 fw-300">
            {t(`${prefix}.discount.formDescriptionDelete`)}
          </p>
        </Col>
      </Row>
      <Row className="pt-5">
        <Col
          xs={{ order: 2, size: 12 }}
          md={{ order: 1, size: 6 }}
          className="ml-auto"
        >
          <Button
            type="button"
            text={t(`common.actions.cancel`)}
            outlined
            onClick={() => toggleModal()}
            fullwidth
          />
        </Col>
        <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 6 }}>
          <div className="pb-3 pb-md-0">
            <Button
              type="button"
              color="danger"
              text={t(`${prefix}.discount.formDeleteButton`)}
              fullwidth
              onClick={onsubmit}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};
