import { Button, addToast, useMobile } from '@octano/global-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useUserState from '../../../../hooks/useUserState';
import { PermissionName } from '../../../../types/Auth';
import { downloadFromBlob } from '../../../../utils/blob';
import GeneratingFileModal from '../GeneratingFileModal';
import NoResultsModal from '../NoResultsModal';
import UploadEmailsModal from '../UploadEmailsModal';
import {
  createReportDataUploadHandler,
  createReportDownloadHandler,
} from './handlerCreator';

enum ModalType {
  NONE,
  UPLOAD_EMAILS_FILE,
  GENERATING_FILE,
  NO_PENDING_STUDENTS,
}

interface Props {
  downloadUrl: string;
}

export default function StudentsWithoutEmailActions({ downloadUrl }: Props) {
  const { isAuthorizedTo } = useUserState();
  const { t } = useTranslation();
  const isMobile = useMobile();

  const [openedModal, setOpenedModal] = useState<ModalType>();

  const handleReportDownload = createReportDownloadHandler({
    beforeDownload: () => setOpenedModal(ModalType.GENERATING_FILE),
    afterDownload: ({ data }: { data: Blob }) => {
      if (data.size === 0) {
        setOpenedModal(ModalType.NO_PENDING_STUDENTS);
      } else {
        setOpenedModal(ModalType.NONE);
      }
    },
    onSuccess: (data: Blob) => {
      if (data.size > 0) {
        const blob = new Blob([data], { type: 'vnd.ms-excel' });
        const name = `${t('reports.pendingStudents.fileName')}.xlsx`;
        downloadFromBlob(blob, name);
      }
    },
    onError: () => {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`reports.errors.errorDownloadingFile`),
      });
    },
  });

  const handleReportDataUpload = createReportDataUploadHandler({
    afterUpload: () => setOpenedModal(ModalType.NONE),
    onSuccess: (data: any) => {
      if (data?.rows_with_conflicts?.length) {
        addToast({
          icon: 'error',
          color: 'warning',
          text: t(`reports.warnings.studentsWithoutEmailUpload`),
          className: 'bigger-toast',
        });
      } else {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`reports.success.studentsWithoutEmailUpload`),
        });
      }
    },
    onError: () => {
      addToast({
        icon: 'error',
        color: 'danger',
        text: t(`reports.errors.studentsWithoutEmailUpload`),
      });
    },
  });

  const handleFileUpload = (file: File) => {
    const data = new FormData();
    data.append('file', file);
    handleReportDataUpload(downloadUrl, data);
  };

  return (
    <>
      <UploadEmailsModal
        isOpen={openedModal === ModalType.UPLOAD_EMAILS_FILE}
        onCancel={() => setOpenedModal(ModalType.NONE)}
        onConfirm={handleFileUpload}
      />
      <NoResultsModal
        isOpen={openedModal === ModalType.NO_PENDING_STUDENTS}
        onConfirm={() => setOpenedModal(ModalType.NONE)}
        texts={{
          title: t(`reports.pendingStudents.noPendingStudents.title`),
          description: t(
            `reports.pendingStudents.noPendingStudents.description`,
          ),
        }}
      />
      <GeneratingFileModal isOpen={openedModal === ModalType.GENERATING_FILE} />
      <div className="d-flex flex-wrap align-items-center " style={{ gap: 12 }}>
        <Button
          text={t(`common.actions.download`)}
          icon="download"
          size="sm"
          outlined
          style={{ width: isMobile ? '90%' : 123 }}
          disabled={
            !isAuthorizedTo([PermissionName.DOWNLOAD_STUDENTS_WITHOUT_EMAIL])
          }
          onClick={() => handleReportDownload(downloadUrl)}
        />

        <Button
          text={t(`common.actions.load`)}
          icon="export"
          size="sm"
          style={{ width: isMobile ? '90%' : 123 }}
          disabled={!isAuthorizedTo([PermissionName.UPLOAD_STUDENTS_EMAILS])}
          onClick={() => setOpenedModal(ModalType.UPLOAD_EMAILS_FILE)}
        />
      </div>
    </>
  );
}
