import { Card, Col, Row } from 'reactstrap';
import { useState, useEffect, useCallback, useRef } from 'react';
import {
  deleteInterimDegree,
  getCurriculum,
  getInterimDegress,
  updateInterimDegreeStatus,
} from '../../../api/requests/academicOfferInterimDegree';
import { Curriculum } from './interfaces/curriculum.interface';
import { useTranslation } from 'react-i18next';
import { addToast, Button } from '@octano/global-ui';
import { InterimDegreeInterimDegressTable } from './parts/InterimDegressTable';
import useUserState from '../../../hooks/useUserState';
import { PermissionName } from '../../../types/Auth';
import CurriculumBox from './parts/CurriculumBox';
import { useParams } from 'react-router-dom';
import GoBackToListButton from '../../../components/buttons/GoBackToListButton';
import DisplayPermissionError from '../../../components/info/DisplayPermissionError';
import {
  InterimDegree,
  InterimDegreeStatus,
} from './interfaces/interimDegree.interface';
import { useHistory } from 'react-router-dom';
import { PathsLayouts } from '../../../config/routes';
import InterimDegreeStatusModal, {
  InterimStatusModalMethods,
} from './parts/InterimDegreeStatusModal';

export const MODULE_PERMISSIONS = [
  PermissionName.ACADEMIC_OFFER_INTERIM_DEGREE_LIST,
];
const dictPrefix = 'interimDegree.interimDegrees';
const ITEMS_PER_PAGE = 10;
const SKIP_CONFIRMATION = true;

const InterimDegreeInterimDegrees = () => {
  const { t } = useTranslation();
  const { isAuthorizedTo } = useUserState();

  const statusModalRef = useRef<InterimStatusModalMethods>(null);

  const history = useHistory();

  const { curriculumId } = useParams<{ curriculumId: string }>();

  const handleCreate = useCallback(() => {
    history.push(
      `${PathsLayouts.academicOffers}/interim-degree/create/${curriculumId}`,
    );
  }, [history, curriculumId]);

  const [tableData, setTableData] = useState<InterimDegree[]>([]);
  const [curriculum, setCurriculum] = useState<Curriculum>();
  const [isLoadingTable, setIsTableLoading] = useState<boolean>(false);
  const [isLoadingAction, setIsActionLoading] = useState<boolean>(false);
  const [detailLoaded, setDetailLoaded] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);

  const handleDetails = useCallback(
    async (id: string | number) => {
      const { data, error } = await getCurriculum(id);

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        setCurriculum(data ?? undefined);
      }
    },
    [t],
  );

  const handleSearch = useCallback(
    async ({ page = 0, id }: { id: string | number; page: number }) => {
      setIsTableLoading(true);
      const { data, error } = await getInterimDegress({
        curriculumId: id,
        items: ITEMS_PER_PAGE,
        page: page,
      });

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        setTableData(data?.data ?? []);
        setTotalPages(data?.total_pages || 0);
        setTotalItems(data?.total || 0);
      }
      setIsTableLoading(false);
    },
    [t],
  );

  const handleDelete = useCallback(
    async (id: number) => {
      if (isLoadingAction) {
        return;
      }
      setIsActionLoading(true);
      const { error } = await deleteInterimDegree(id);

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnDelete`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        handleSearch({ page: currentPage, id: curriculumId });
        addToast({
          text: t(`${dictPrefix}.successDelete`),
          color: 'success',
          icon: 'check',
        });
      }
      setIsActionLoading(false);
    },
    [t, currentPage, curriculumId, handleSearch, isLoadingAction],
  );

  const handleUpdate = useCallback(
    async (id: number, status: InterimDegreeStatus) => {
      if (isLoadingAction) {
        return;
      }
      setIsActionLoading(true);
      const { error } = await updateInterimDegreeStatus(id, status);

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnUpdate`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        handleSearch({ page: currentPage, id: curriculumId });
        addToast({
          text: t(`${dictPrefix}.successUpdate`),
          color: 'success',
          icon: 'check',
        });
      }
      setIsActionLoading(false);
    },
    [t, currentPage, curriculumId, handleSearch, isLoadingAction],
  );

  const handleRequestUpdate = useCallback(
    async (id: number, status: InterimDegreeStatus) => {
      if (isLoadingAction) {
        return;
      }
      if (!SKIP_CONFIRMATION) {
        statusModalRef?.current?.open(id, status);
      } else {
        handleUpdate(id, status);
      }
    },
    [isLoadingAction, handleUpdate],
  );

  useEffect(() => {
    if (curriculumId) {
      handleSearch({ page: currentPage, id: curriculumId });
    }
  }, [handleSearch, currentPage, curriculumId]);

  useEffect(() => {
    if (!detailLoaded && curriculumId) {
      handleDetails(curriculumId);
      setDetailLoaded(true);
    }
  }, [handleDetails, detailLoaded, curriculumId]);

  if (!isAuthorizedTo(MODULE_PERMISSIONS, true)) {
    return (
      <div className="w-full px-3">
        <DisplayPermissionError permissions={MODULE_PERMISSIONS} insideCard />
      </div>
    );
  }

  return (
    <>
      <Card className="p-4 mx-3">
        <Row>
          <Col className="mb-4" xs={12}>
            <div className="d-flex flex-wrap w-100 align-items-start justify-content-center">
              <GoBackToListButton text={t(`${dictPrefix}.back`)} />
              <div className="flex-fill px-2" />
              <CurriculumBox
                studyPlan={curriculum?.studyPlanVersion?.studyPlan?.name}
                studyPlanCode={curriculum?.studyPlanVersion?.studyPlan?.code}
                version={curriculum?.studyPlanVersion?.name}
                mention={!curriculum?.isPrimary ? curriculum?.name : '-'}
                school={curriculum?.studyPlanVersion?.studyPlan?.school?.name}
              />
            </div>
          </Col>
          <Col className="mb-4" xs={12}>
            <div className="d-flex flex-wrap w-100 align-items-center justify-content-center">
              <p className="fs-20 text-uppercase text-primary mb-0 fw-700">
                {t(`${dictPrefix}.pageTitle`)}
              </p>
              <div className="flex-fill px-2" />
              {!!isAuthorizedTo([
                PermissionName.ACADEMIC_OFFER_INTERIM_DEGREE_MANAGE,
              ]) && (
                <Button
                  text={t(`${dictPrefix}.create`)}
                  size="md"
                  onClick={handleCreate}
                />
              )}
            </div>
          </Col>
          <Col xs={12}>
            <InterimDegreeInterimDegressTable
              data={!isLoadingAction ? tableData : []}
              itemPerPage={ITEMS_PER_PAGE}
              totalItems={totalItems}
              totalPages={totalPages}
              currentPage={currentPage}
              editEnabled={isAuthorizedTo([
                PermissionName.ACADEMIC_OFFER_INTERIM_DEGREE_MANAGE,
              ])}
              removeEnabled={isAuthorizedTo([
                PermissionName.ACADEMIC_OFFER_INTERIM_DEGREE_MANAGE,
              ])}
              isLoadingResults={isLoadingTable || isLoadingAction}
              onPressDelete={handleDelete}
              onPressStatus={handleRequestUpdate}
              onChangePage={(page) => {
                setCurrentPage(page);
              }}
            />
          </Col>
        </Row>
      </Card>
      <InterimDegreeStatusModal
        ref={statusModalRef}
        dictPrefix={`${dictPrefix}.changeStatusModal`}
        onSave={handleUpdate}
      />
    </>
  );
};

export default InterimDegreeInterimDegrees;
