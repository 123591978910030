import { generatePath } from 'react-router-dom';

const ACADEMIC_RECORD = {
  BASE: '/maintainers-portal/academic-record-load/base',
  TEMPLATE: '/maintainers-portal/academic-record-load/template',
  UPLOAD: '/maintainers-portal/academic-record-load',
};

const AUTH = {
  LOGIN: '/credential-login',
  REQUEST_ACTIVATION_EMAIL: '/check-activate-account',
  REQUEST_FORGOT_PASSWORD_EMAIL: '/recovery-password',
  ACTIVATE_ACCOUNT: '/activate-account',
  CHANGE_PASSWORD: '/reset-password',
  CHECK_PASSWORD_CHANGE_TOKEN: '/check-token',
};

const BASE = {
  BASE: '/maintainers-portal',
};

const ACADEMIC_STRUCTURE = {
  PERIODS: '/maintainers-portal/academic-structure/periods',
  PERIODS_TYPE: '/maintainers-portal/academic-structure/periods-types',
  PERIODS_TYPE_ALL: '/maintainers-portal/academic-structure/periods-types/all',
  MODALITY: '/maintainers-portal/academic-structure/modalities',
  CAMPUS: '/maintainers-portal/academic-structure/campuses',
  SCHOOLS: '/maintainers-portal/academic-structure/schools',
  SCHEDULES: '/maintainers-portal/academic-structure/schedules',
  SCHEDULES_CALENDAR:
    '/maintainers-portal/academic-structure/schedules/calendar',
  GET_SCHEDULES_CALENDAR: (scheduleId: number | string) =>
    `/maintainers-portal/academic-structure/schedules/calendar/${scheduleId}`,
  PUT_SCHEDULES_CALENDAR: (scheduleId: number | string) =>
    `/maintainers-portal/academic-structure/schedules/calendar/${scheduleId}`,
};

// Endpoints para la modificación de inscripción de asignaturas
const COURSE_REGISTRATION = {
  // Lista
  LIST_FORM_DATA:
    '/maintainers-portal/subject-sections/enrollment-modification/form-data',
  LIST: '/maintainers-portal/subject-sections/enrollment-modification',

  // Detalle
  GET_DETAIL: (studyPlanEnrollmentId: string | number) =>
    `/maintainers-portal/subject-sections/enrollment-modification/${studyPlanEnrollmentId}`,
  AVAILABLE_COURSES:
    '/maintainers-portal/subject-sections/enrollment-modification/list-course-available-in-period',
  GET_AVAILABLE_SECTIONS: (courseId: number) =>
    `/maintainers-portal/subject-sections/enrollment-modification/list-available-section-for-course/${courseId}`,
  SAVE_ADMINISTRATIVE_ENROLLMENT:
    '/maintainers-portal/subject-sections/enrollment-modification/',
  DETELE_COURSE_REGISTRATION:
    '/maintainers-portal/subject-sections/enrollment-modification/',
  UPDATE_COURSE_REGISTRATION_SECTION: `/maintainers-portal/subject-sections/enrollment-modification/`,
};

const ACADEMIC_OFFERS_MAINTAINER = {
  GET_BY_ID: (academicOfferId: number) =>
    `maintainers-portal/academic-offer/vacant-offers/edit/${academicOfferId}`,
  PUT_ACADEMIC_OFFER: (academicOfferId: string) =>
    `maintainers-portal/academic-offer/vacant-offers/${academicOfferId}`,
  DELETE_ACADEMIC_OFFER: (academicOfferId: string) =>
    `maintainers-portal/academic-offer/vacant-offers/${academicOfferId}`,
  GET_VALIDATE_QUOTA: (academicOfferId: string) =>
    `maintainers-portal/academic-offer/vacant-offers/validate-quota/${academicOfferId}`,
  POST_VALIDATE_QUOTA: (academicOfferId: number) =>
    `maintainers-portal/academic-offer/vacant-offers/update-quota/${academicOfferId}`,
  GET_COMPLETE_OFFER: (academicOfferId: number) =>
    `maintainers-portal/academic-offer/vacant-offers/complete-offer/${academicOfferId}`,
  VACANT_OFFER_GET_PENDING: (academicOfferId: number) =>
    `maintainers-portal/academic-offer/vacant-offers/pending-offers/${academicOfferId}`,
  POST_OFFER_REPORT: (academicOfferId: number) =>
    `maintainers-portal/academic-offer/vacant-offers/offer-report/${academicOfferId}`,
  POST_VISUALIZE: (academicOfferId: number) =>
    `maintainers-portal/academic-offer/vacant-offers/visualize/${academicOfferId}`,
  GET_RESOLUTION: (resolutionId: number) =>
    `maintainers-portal/academic-offer/vacant-offers/resolution-url/${resolutionId}`,
  POST_RESOLUTION: (resolutionId: number) =>
    `maintainers-portal/academic-offer/vacant-offers/attach-resolution/${resolutionId}`,
  VACANT_OFFERS_GET_ALL_NON_DRAFT:
    'maintainers-portal/academic-offer/vacant-offers/non-draft',
  VACANT_OFFERS_FORM_DATA:
    'maintainers-portal/academic-offer/vacant-offers/form-data',
  BASE: 'maintainers-portal/academic-offer/vacant-offers',
};

const PACKAGES = {
  DETAIL: '/maintainers-portal/sections-packages/detail',
  SECTIONS: (packageId: number | string) =>
    `/maintainers-portal/sections-packages/sections/${packageId}`,
  TIME_CALENDAR: '/maintainers-portal/sections-packages/time-calendar',
  ENROLLED_STUDENTS: '/maintainers-portal/sections-packages/enrolled-students',
  ACADEMIC_OFFER_STUDY_PLAN_VERSION_OFFER:
    '/maintainers-portal/sections-packages/studyplan-detail',
  PENDING_STUDENTS: '/maintainers-portal/sections-packages/pending-students',
  ENROLL_NEW_STUDENTS:
    '/maintainers-portal/sections-packages/enroll-new-students',
  GET_SECTION_PACKAGE_FORM_DATA:
    '/maintainers-portal/sections-packages/form-data',
  GET_SECTION_PACKAGE: '/maintainers-portal/sections-packages',
};

const PARAMETERS = {
  ROLES: '/maintainers-portal/admin-roles',
  COUNTRIES: '/countries',
  INSTITUTION_TYPES: '/postulation/institutions-types',
  REGION_AND_COMMUNES: '/region/all',
};

const PERIODS = {
  GET_ALL: '/maintainers-portal/section-packages-load/periods',
  GET_BY_ID: '/maintainers-portal/section-packages-load/detail',
  BASE_INFO_DOCUMENT: (periodId: string | number) =>
    `/maintainers-portal/section-packages-load/base-information/${periodId}`,
  LOADED_INFO_DOCUMENT:
    '/maintainers-portal/section-packages-load/loaded-sections-information',
  UPLOAD_SECTIONS_DOCUMENT:
    '/maintainers-portal/section-packages-load/create-from-file',
  DOWNLOAD_ERROR_DOCUMENT:
    '/maintainers-portal/section-packages-load/section-load-error-file',
};

//TUITION_PROCESS
const POSTULANTS = {
  //TUITION_PROCESS-->POSTULANTS
  SEARCHRUT: '/maintainers-portal/tuition-process/postulants/search-rut',
  //TUITION_PROCESS-->POSTULANTS
  SEARCHBYPASSPORT:
    '/maintainers-portal/tuition-process/postulants/search-passport',
  //TUITION_PROCESS-->POSTULANTS
  CREATE: '/maintainers-portal/tuition-process/postulants',
  //TUITION_PROCESS-->POSTULANTS
  UPDATE: '/maintainers-portal/tuition-process/postulants',

  GETDOCUMENTS: (studyPlanVersionOfferId: number) =>
    generatePath('/postulants/:studyPlanVersionOfferId/documentation', {
      studyPlanVersionOfferId,
    }),
};

const POSTULATION = {
  //TUITION_PROCESS-->CAREER_SELECTION
  CREATE:
    '/maintainers-portal/tuition-process-steps/study-plan-selection/postulations',
  //TUITION_PROCESS-->STUDENT_FILE
  INFORMATION_WAITING_LIST: (postulantId?: number) =>
    `/postulation/information-waiting-list${postulantId && '/' + postulantId}`,
  //TUITION_PROCESS-->CAREER_SELECTION
  UPDATE: (postulationDetailId?: string) =>
    `/maintainers-portal/tuition-process-steps/study-plan-selection/postulations/${postulationDetailId}`,
};

const SECTION_ATTRIBUTES = {
  GET_ALL_PERIODS: '/maintainers-portal/section-attributes',
  GET_PERIOD_BY_ID: '/maintainers-portal/section-attributes',
  BASE_INFO_DOCUMENT: (periodId: string | number) =>
    `/maintainers-portal/section-attributes/base-info/${periodId}`,
  UPLOAD_ATTRIBUTES_DOCUMENT: '/maintainers-portal/section-attributes',
  DOWNLOAD_ERROR_DOCUMENT:
    '/maintainers-portal/section-attributes/section-attributes-error-file',
};

// Endpoints del mantenedor de secciones
const SECTION_MAINTAINER = {
  // Editar
  GET_DETAIL: (sectionId: number) =>
    `/maintainers-portal/subject-sections/modification-section/edit/${sectionId}`,

  // Editar General
  GET_GENERAL: (sectionId: number) =>
    `/maintainers-portal/subject-sections/modification-section/edit/${sectionId}/general`,
  PUT_GENERAL: (sectionId: number) =>
    `/maintainers-portal/subject-sections/modification-section/edit/${sectionId}/general`,
  EDIT_SEARCH_TEACHERS: (sectionId: number | string) =>
    `/maintainers-portal/subject-sections/modification-section/edit/${sectionId}/general/search-teacher`,
  EDIT_SEARCH_ASSISTANT: (sectionId: number | string) =>
    `/maintainers-portal/subject-sections/modification-section/edit/${sectionId}/general/search-assistant`,
  EDIT_CREATE_ASSISTANT: (sectionId: number | string) =>
    `/maintainers-portal/subject-sections/modification-section/edit/${sectionId}/assistant`,

  // Editar Horarios
  GET_SCHEDULES: (sectionId: number) =>
    `/maintainers-portal/subject-sections/modification-section/edit/${sectionId}/schedules`,
  PUT_SCHEDULES: (sectionId: number) =>
    `/maintainers-portal/subject-sections/modification-section/edit/${sectionId}/schedules`,
  EDIT_SEARCH_RESPONSIBLE: (sectionId: number | string) =>
    `/maintainers-portal/subject-sections/modification-section/edit/${sectionId}/schedules/search-responsable`,

  // Editar Paquetes
  EDIT_PACKAGES_SEARCH_PACKAGES: (
    sectionId: number | string,
    periodId: number,
  ) =>
    `/maintainers-portal/subject-sections/modification-section/edit/${sectionId}/packages/periods/${periodId}`,
  EDIT_PACKAGES_TIME_CALENDAR: (sectionId: number | string) =>
    `/maintainers-portal/subject-sections/modification-section/edit/${sectionId}/packages/time-calendar`,
  EDIT_PACKAGES: (sectionId: string | number) =>
    `/maintainers-portal/subject-sections/modification-section/edit/${sectionId}/packages/`,
  EDIT_PACKAGES_PACKAGE: (
    sectionId: string | number,
    packageId: string | number,
  ) =>
    `/maintainers-portal/subject-sections/modification-section/edit/${sectionId}/packages/sections/${packageId}`,

  // Listar
  FORM_DATA:
    '/maintainers-portal/subject-sections/modification-section/form-data',
  LIST: '/maintainers-portal/subject-sections/modification-section',

  // Crear
  GET_CREATE:
    '/maintainers-portal/subject-sections/modification-section/create',
  COURSES_LIST:
    '/maintainers-portal/subject-sections/modification-section/create/courses',
  SEARCH_TEACHERS:
    '/maintainers-portal/subject-sections/modification-section/create/search-teacher',
  SEARCH_ASSISTANT:
    '/maintainers-portal/subject-sections/modification-section/create/search-assistant',
  CREATE_SECTION:
    '/maintainers-portal/subject-sections/modification-section/create',
};

const STUDENT_STATUS = {
  STATUS_OPTIONS: '/maintainers-portal/students-status/form',
  REPORT_STATUS_STUDENT: '/maintainers-portal/students-status/report',
  LIST: '/maintainers-portal/students-status',
  PUT_CHANGE_STATUS: (studyPlanEnrollmentId: number) =>
    `/maintainers-portal/students-status/${studyPlanEnrollmentId}`,
  GET_STUDENT_STATUS: (studyPlanEnrollmentId: number) =>
    `/maintainers-portal/students-status/${studyPlanEnrollmentId}`,
  PUT_UPDATE_STATUS_DOCUMENT: (studyPlanEnrollmentId: number) =>
    `/maintainers-portal/students-status/${studyPlanEnrollmentId}/document`,
};

//Enpoints de admisión
const STUDY_PLANS_OFFER = {
  //se usa en matrícula presencial
  TO_STATUS_DESISTED: '/postulation-detail/status-desisted',
  TO_STATUS_RETRACTED: '/postulation-detail/status-retracted',
};

// Endpoints relacionados con el proceso de matrícula
const TUITION_PROCESS = {
  //TUITION_PROCESS->CAREER_SELECTION
  STUDY_PLAN_QUOTA:
    '/maintainers-portal/tuition-process-steps/study-plan-selection/study-plans/quota',
  ACTIVE_TUITION_PROCESS: '/maintainers-portal/tuition-process-steps/active',
  LOGIN: '/login',
  TUITION_PROCESS_STEPS: '/maintainers-portal/tuition-process-steps',
  TUITION_PROCESS_STEPS_STATUS: (
    postulationDetailId?: number | string,
    postulantId?: number | string,
  ) =>
    `/maintainers-portal/tuition-process-steps/${postulationDetailId}/${postulantId}`,
  STUDENT_FILE: '/maintainers-portal/tuition-process-steps/student-file',
  SUSTAINER: '/maintainers-portal/tuition-process-steps/sustainer',
  COSIGNER: '/maintainers-portal/tuition-process-steps/cosigner',
  TERMS_AND_CONDITIONS:
    '/maintainers-portal/tuition-process-steps/terms-and-conditions',
  TERMS_AND_CONDITIONS_PREVIEW_TOKEN:
    '/maintainers-portal/tuition-process-steps/terms-and-conditions/preview/token',
  TERMS_AND_CONDITIONS_PREVIEW:
    '/maintainers-portal/tuition-process-steps/terms-and-conditions/preview',
  PAYMENT: '/tuition-process-steps/payment',
  SIGN_DOCUMENTATION: (postulationDetailId: string | number) =>
    `/maintainers-portal/tuition-process-steps/sign-documentation/${postulationDetailId}`,
  SIGN_DOCUMENTATION_UPLOAD_DOCUMENTATION: (
    postulationDetailId: string | number,
    uploadMethod: string,
  ) =>
    `/maintainers-portal/tuition-process-steps/sign-documentation/${postulationDetailId}/upload-documentation/${uploadMethod}`,
  SIGN_DOCUMENTATION_SIGN: `/tuition-process-steps/sign-documentation/sign`,
  SIGN_DOCUMENTATION_REQUEST_SIGN: (postulationDetailId: number | string) =>
    `/maintainers-portal/tuition-process-steps/sign-documentation/${postulationDetailId}/upload-documentation/signature/request`,
  SIGN_DOCUMENTATION_IDENTITY_CARD_UPLOAD: (postulationDetailId?: number) =>
    `/maintainers-portal/tuition-process-steps/sign-documentation/identity-card/${postulationDetailId}`,
  SIGN_DOCUMENTATION_PREVIEW_TOKEN: (postulationDetailId: number | string) =>
    `/maintainers-portal/tuition-process-steps/sign-documentation/${postulationDetailId}/preview-document/token`,
  SIGN_DOCUMENTATION_PREVIEW: (postulationDetailId: number | string) =>
    `/maintainers-portal/tuition-process-steps/sign-documentation/${postulationDetailId}/download`,
  SIGN_DOCUMENTATION_VERIFY: (postulationDetailId: number | string) =>
    `/maintainers-portal/tuition-process-steps/sign-documentation/${postulationDetailId}`,
  SIGN_DOCUMENTATION_LEM_NEM: (postulationDetailId?: number) =>
    `/maintainers-portal/tuition-process-steps/sign-documentation/upload-documents/${postulationDetailId}`,
  SIGN_DOWNLOAD_CONTRACT: (postulationDetailId?: number) =>
    `/maintainers-portal/tuition-process-steps/sign-documentation/download-contract/${postulationDetailId}`,
  COMPLETED: '/maintainers-portal/tuition-process-steps/completed',
  //TUITION_PROCESS-CAREER_SELECTION
  CHANGE_ENROLLED_STUDY_PLAN:
    '/maintainers-portal/tuition-process-steps/study-plan-selection/study-plan-change',
  GET_DESIST_STUDY_PLAN_CHANGE: (postulationDetailId: number) =>
    `/maintainers-portal/tuition-process-steps/status-change-desisted/${postulationDetailId}`,
  TARIFF_QUOTAS: (postulationDetailId: string | number) =>
    `/maintainers-portal/tuition-process-steps/tariff/inf/${postulationDetailId}`,
  TARIFF_QUOTAS_SIMULATION: (postulationDetailId: string | number) =>
    `/maintainers-portal/tuition-process-steps/tariff/simulation/${postulationDetailId}`,
  TARIFF_QUOTAS_FORM_DATA: (postulationDetailId: string | number) =>
    `/maintainers-portal/tuition-process-steps/tariff/form-data/${postulationDetailId}`,
  TARIFF_QUOTAS_SAVE: (postulationDetailId: string | number) =>
    `/maintainers-portal/tuition-process-steps/tariff/save-quota/${postulationDetailId}`,
  SIGN_DOCUMENTATION_SUSTAINER_INCOME: (postulationDetailId?: number) =>
    `/maintainers-portal/tuition-process-steps/sign-documentation/upload-sustainer-income/${postulationDetailId}`,
  SIGN_DOCUMENTATION_SUSTAINER_INCOME_OTHERS: (postulationDetailId?: number) =>
    `/maintainers-portal/tuition-process-steps/sign-documentation/upload-other-document/${postulationDetailId}`,
  POSTULANT_PAYMENT_STEP: (postulationDetailId: string | number) =>
    `/maintainers-portal/tuition-process-steps/postulant/payment/${postulationDetailId}`,
  POSTULANT_PAYMENT_STEP_FINISH: (postulationDetailId: string | number) =>
    `/maintainers-portal/tuition-process-steps/postulant/payment/${postulationDetailId}`,
  POSTULANT_PAYMENT_VOUCHER_TOKEN: (postulationDetailId?: string | number) =>
    postulationDetailId
      ? `/maintainers-portal/tuition-process-steps/postulant/payment/${postulationDetailId}/download-voucher/token`
      : `/maintainers-portal/tuition-process-steps/postulant/payment/download-voucher/token`,
  POSTULANT_PAYMENT_ONLINE_VOUCHER:
    '/maintainers-portal/tuition-process-steps/postulant/payment/online/download-voucher',
  POSTULANT_PAYMENT_ONSITE_VOUCHER:
    '/maintainers-portal/tuition-process-steps/postulant/payment/onsite/download-voucher',
};

const TUITION_CONTINUITY_PROCESS_MANAGEMENT = {
  BASE: '/maintainers-portal/tuition-continuity-process/form',
  LIST: '/maintainers-portal/tuition-continuity-process',
  DISCOUNT: '/maintainers-portal/tuition-continuity-process/discount',
  DETAIL: (studyPlanVersionOfferId: number | string) =>
    `/maintainers-portal/tuition-continuity-process/${studyPlanVersionOfferId}`,
  DETAIL_STUDENTS: (studyPlanVersionOfferId: number | string) =>
    `/maintainers-portal/tuition-continuity-process/${studyPlanVersionOfferId}/students`,
};

const REPORTS = {
  GET_ALL: '/report',
  GET_STUDY_PLAN_VERSION_REPORTS: '/report/study-plan-version/form',
  DOWNLOAD_BY_URL: (url: string) => `/report${url}`,
  UPLOAD_DATA_BY_URL: (url: string) => `/report${url}`,
};

const TEACHER = {
  SEARCH: '/maintainers-porta/teachers-activation/search',
  SEND_ACTIVATION_LINK:
    '/maintainers-porta/teachers-activation/activate-teacher',
  SEND_PASSWORD_RECOVERY_LINK:
    '/maintainers-porta/teachers-activation/recovery-password',
  DEACTIVATE_TEACHER:
    '/maintainers-porta/teachers-activation/deactivate-teacher',
};

const MAINTAINERPORTAL = {
  GET_PERIOD_MAINTAINER_PORTAL: '/maintainers-portal/period-close/form',
  FILTER_GET_SECTION_BY_PERIOD_ID_AND_STATE:
    '/maintainers-portal/period-close/filter/form',
  GET_SECTION_BY_PERIOD_ID_AND_STATE: '/maintainers-portal/period-close',
  GET_SECTION_BY_ID: (sectionId: string) =>
    `/maintainers-portal/period-close/review/${sectionId}`,
  POST_CLOSED_SECTION: (sectionId: number) =>
    `/maintainers-portal/period-close/close/${sectionId}`,
  POST_REJECT_CLOSURE_SECTION: (sectionId: number) =>
    `/maintainers-portal/period-close/reopen/${sectionId}`,
  DOWNLOAD_PDF_SECTION_CLOSE: (sectionId: number) =>
    `/maintainers-portal/period-close/${sectionId}/download`,
};

const GRADES_ENTRY = {
  CONFIG:
    '/maintainers-portal/general-configuration/academic-configuration/grade-scale',
};

const SETTINGS = {
  TIME_STATUS_LESSONS:
    '/maintainers-portal/general-configuration/academic-configuration/time-status-lessons',
  TIMEZONE: '/settings/timezone',
  FINANTIAL: 'maintainers-portal/general-configuration/finantial-configuration',
};

const STUDENTS_POST_CLOSE = {
  SEARCH: '/maintainers-portal/students-post-close',
  STUDENT_DETAIL: (courseEnrollmentId: string | number) =>
    `/maintainers-portal/students-post-close/${courseEnrollmentId}`,
  STUDENT_GRADES_UPDATE: (courseEnrollmentId: string) =>
    `/maintainers-portal/students-post-close/${courseEnrollmentId}`,
};

const PAYMENT_HUB = {
  PAYMENT_HUB_FORM_FIELDS: '/maintainers-portal/payment-hub/form',
  // Collected
  PAYMENT_HUB_COLLECTED_LIST: '/maintainers-portal/payment-hub/collected',
  PAYMENT_HUB_COLLECTED_DOWNLOAD:
    '/maintainers-portal/payment-hub/collected/download',
  // Agreed
  PAYMENT_HUB_AGREED_LIST: '/maintainers-portal/payment-hub/agreed',
  PAYMENT_HUB_AGREED_DOWNLOAD:
    '/maintainers-portal/payment-hub/agreed/download',
};

const GRADUATION_PROCESS_MASSIVE = {
  CURRENT_PERIOD_AND_FACULTIES: '/maintainers-portal/graduation-process/form',
  STUDY_PLANS_BY_FACULTY: '/maintainers-portal/graduation-process',
  PROCESS_GRADUATES_BY_STUDY_PLANS: '/maintainers-portal/graduation-process',
  DOWNLOAD_GRADUATES_LIST: (studyPlanId: number) =>
    `/maintainers-portal/graduation-process/student-processed/${studyPlanId}`,
  GET_GRADUATES_LIST: '/maintainers-portal/graduation-process/graduates',
  FILTER_GRADUATES_LIST: '/maintainers-portal/graduation-process/filter/form',
  DOWNLOAD_GRADUATES_LIST_BY_FILTER:
    '/maintainers-portal/graduation-process/graduates/excel',
};

const GRADUATION_PROCESS_SINGLE = {
  SEARCH_STUDENTS: '/maintainers-portal/single-graduation-process',
  PROCESS_GRADUATE: '/maintainers-portal/single-graduation-process',
  GET_SUMMARY: '/maintainers-portal/single-graduation-process/summary',
};

const TUITION_CONTINUITY_TARIFF = {
  TUITION_LIST: '/maintainers-portal/tuition-continuity-tariff/tuition-values',
  PERIODS: '/maintainers-portal/tuition-continuity-tariff/available-periods',
  SAVE_TUITION_VALUE:
    '/maintainers-portal/tuition-continuity-tariff/tuition-values',
  GET_PERIOD_AND_OFFER:
    '/maintainers-portal/tuition-continuity-tariff/period-school',
  GET_TARIFF_LIST: '/maintainers-portal/tuition-continuity-tariff/tariff',
  SAVE_TARIFFS: '/maintainers-portal/tuition-continuity-tariff/tariff',
};

const TUITION_CONTINUITY_DOCUMENT_REVIEW = {
  LIST: (periodId: number | string) =>
    `/maintainers-portal/tuition-continuity-process/document-review/${periodId}`,
  STUDENT_TABS:
    '/maintainers-portal/tuition-continuity-process/document-review/student-tabs',
  STUDENT: (tcProcessId: number | string) =>
    `maintainers-portal/tuition-continuity-process/document-review-student/${tcProcessId}`,
  DOCUMENT: (documentId: number | string) =>
    `maintainers-portal/tuition-continuity-process/document-review-student/document/${documentId}`,
  DOCUMENT_CHANGE_STATUS: (documentId: number | string) =>
    `maintainers-portal/tuition-continuity-process/document-review-student/document/${documentId}`,
  DOCUMENT_DELETE: (documentId: number | string) =>
    `maintainers-portal/tuition-continuity-process/document-review-student/document/${documentId}`,
  DOCUMENT_UPLOAD: (tcProcessId?: number | string) =>
    `maintainers-portal/tuition-continuity-process/document-review-student/${tcProcessId}/document`,
  DOCUMENT_OTHER: (documentId: number | string) =>
    `maintainers-portal/tuition-continuity-process/document-review-student/document-other/${documentId}`,
  DOCUMENT_OTHER_DELETE: (documentId: number | string) =>
    `maintainers-portal/tuition-continuity-process/document-review-student/document-other/${documentId}`,
  DOCUMENT_OTHER_UPLOAD: (tcProcessId?: number | string) =>
    `maintainers-portal/tuition-continuity-process/document-review-student/document-other/${tcProcessId}`,
};

const CURRICULUM_PROGRESS = {
  BASE: 'maintainers-portal/student-curriculum-progress',
  STUDENTS: 'maintainers-portal/student-curriculum-progress/students',
  STUDENT: (studyPlanEnrollmentId: number | string) =>
    `maintainers-portal/student-curriculum-progress/students/${studyPlanEnrollmentId}`,
  HISTORY_DOWNLOAD: (studyPlanEnrollmentId: number | string) =>
    generatePath(
      `maintainers-portal/student-curriculum-progress/students/:studyPlanEnrollmentId/history-document`,
      { studyPlanEnrollmentId },
    ),
};

const STUDENT_COURSE_VALIDATION = {
  INTERNAL: {
    BASE: 'maintainers-portal/student-course-validation/internal',
    VALIDATE: (studyPlanEnrollmentId: number | string) =>
      `maintainers-portal/student-course-validation/internal/${studyPlanEnrollmentId}`,
    STUDENTS: 'maintainers-portal/student-course-validation/internal/students',
    STUDY_PLAN_ENROLLMENT: (studyPlanEnrollmentId: number | string) =>
      `maintainers-portal/student-course-validation/internal/students/${studyPlanEnrollmentId}`,
    CURRICULUM: (studyPlanEnrollmentId: number | string) =>
      `maintainers-portal/student-course-validation/internal/curriculum/${studyPlanEnrollmentId}`,
  },
};

const STUDENT_PETITIONS = {
  GET_STUDENT_PETITIONS_LIST: '/maintainers-portal/student-petitions',
  GET_STUDENT_RESOLVED_PETITIONS_LIST:
    '/maintainers-portal/student-petitions/resolved',
  FILTER_STUDENT_PETITIONS_LIST:
    '/maintainers-portal/student-petitions/filter/form',
  FILTER_STUDENT_PETITIONS_EXCEL_LIST:
    '/maintainers-portal/student-petitions/filter/excel/form',
  DOWNLOAD_STUDENT_PETITIONS_LIST_BY_FILTER:
    '/maintainers-portal/student-petitions/report/excel',
};

const STUDENT_PETITION_DETAIL = {
  GET_STUDENT_PETITION: (petitionId: number | string) =>
    `/maintainers-portal/student-petitions/${petitionId}`,
  PUT_STUDENT_PETITION: (petitionId: number | string) =>
    `/maintainers-portal/student-petitions/${petitionId}`,
  GET_STUDENT_PETITION_FILES: (fileId: number | string) =>
    `/maintainers-portal/student-petitions/file/${fileId}`,
  GET_STUDENT_PETITION_STORY: (petitionId: number | string) =>
    `/maintainers-portal/student-petitions/${petitionId}/history`,
};

const STUDENTS_DEGREE = {
  GET_STUDENTS_DEGREE_LIST: '/maintainers-portal/students-degree',
  FILTER_STUDENTS_DEGREE_LIST:
    '/maintainers-portal/students-degree/filter/form',
  DOWNLOAD_STUDENTS_DEGREE_LIST_BY_FILTER:
    '/maintainers-portal/students-degree/report/excel',
};

const STUDENTS_DEGREE_STUDENT = {
  GET_BASE: (studyPlanEnrollmentId: number | string) =>
    generatePath('/maintainers-portal/students-degree/:studyPlanEnrollmentId', {
      studyPlanEnrollmentId,
    }),
  GET_ACTIVITY: (degreeProcessActivityId: number | string) =>
    generatePath(
      '/maintainers-portal/students-degree/activity/:degreeProcessActivityId',
      {
        degreeProcessActivityId,
      },
    ),
  POST_CREATE_RENDITION: (degreeProcessActivityId: number | string) =>
    generatePath(
      '/maintainers-portal/students-degree/activity/:degreeProcessActivityId/rendition',
      {
        degreeProcessActivityId,
      },
    ),
  POST_COMPLETE: (studyPlanEnrollmentId: number | string) =>
    generatePath('/maintainers-portal/students-degree/:studyPlanEnrollmentId', {
      studyPlanEnrollmentId,
    }),
};

const ENROLLMENT_SOFT_CHANGE = {
  GET_REGULAR_STUDENTS_LIST: `maintainers-portal/enrollment-soft-change`,
  GET_REGULAR_STUDENTS_LIST_CONTINUITY: `maintainers-portal/enrollment-soft-change/continuity`,
  GET_FILTERS: `maintainers-portal/enrollment-soft-change/form-data`,
  MODIFY_FORM_DATA: (studyPlanEnrollmentId: string | number) =>
    `maintainers-portal/enrollment-soft-change/${studyPlanEnrollmentId}/form-data`,
  MODIFY: (studyPlanEnrollmentId: string | number) =>
    `maintainers-portal/enrollment-soft-change/${studyPlanEnrollmentId}`,
  VERIFY_SOFTCHANGE_TO_CONTINUITY: (
    accoundId: string | number,
    studyPlanEnrollmentId: string | number,
  ) =>
    `maintainers-portal/enrollment-soft-change/continuity/${accoundId}/${studyPlanEnrollmentId}/verify`,
  MODIFY_FORM_DATA_TO_CONTINUITY: (studyPlanEnrollmentId: string | number) =>
    `maintainers-portal/enrollment-soft-change/continuity/${studyPlanEnrollmentId}/form-data`,
  MODIFY_TO_CONTINUITY: (studyPlanEnrollmentId: string | number) =>
    `maintainers-portal/enrollment-soft-change/continuity/${studyPlanEnrollmentId}`,
};

const USERS_AND_ROLES = {
  POST_CREATE_ADMIN_ACCOUNT: '/maintainers-portal/admin-account',
  GET_ADMIN_ACCOUNT_LIST: '/maintainers-portal/admin-account',
  GET_ADMIN_ACCOUNT: (country: number | string, passportNumber: string) =>
    `/maintainers-portal/admin-account/search-passport/${country}/${passportNumber}`,
  GET_ADMIN_ACCOUNT_BY_RUT: (rut: string) =>
    `/maintainers-portal/admin-account/search-rut/${rut}`,
  UPDATE_ADMIN_ACCOUNT: (accountId: number | string) =>
    `/maintainers-portal/admin-account/${accountId}`,
  PUT_ADMIN_ACCOUNT_STATUS: (accountId: number | string) =>
    `/maintainers-portal/admin-account/status/${accountId}`,
};

const ACADEMIC_OFFER_INTERIM_DEGREE = {
  INTERIM_DEGREES: '/maintainers-portal/academic-offer/interim-degree',
  INTERIM_DEGREES_ACTIVITIES:
    '/maintainers-portal/academic-offer/interim-degree/activities',
  INTERIM_DEGREE: (id: string | number) =>
    `/maintainers-portal/academic-offer/interim-degree/${id}`,
  INTERIM_DEGREE_STATUS: (id: string | number) =>
    `/maintainers-portal/academic-offer/interim-degree/${id}/status`,
  // Curriculums
  CURRICULUMS_FORM:
    '/maintainers-portal/academic-offer/interim-degree/curriculums/form',
  CURRICULUMS_LIST:
    '/maintainers-portal/academic-offer/interim-degree/curriculums/list',
  CURRICULUM: (id: string | number) =>
    `/maintainers-portal/academic-offer/interim-degree/curriculums/${id}`,
  CURRICULUM_FULL: (id: string | number) =>
    `/maintainers-portal/academic-offer/interim-degree/curriculums/full-details/${id}`,
};

const INTERIM_DEGREE_GRADUATION_PROCESS_MASSIVE = {
  INTERIM_DEGREES:
    '/maintainers-portal/interim-degree-graduation-process/massive',
  FORM: '/maintainers-portal/interim-degree-graduation-process/massive/form',
  STUDENTS: (id: string | number) =>
    `/maintainers-portal/interim-degree-graduation-process/massive/${id}/students`,
  STUDENTS_DOWNLOAD: (id: string | number) =>
    `/maintainers-portal/interim-degree-graduation-process/massive/${id}/students/download`,
};

const INTERIM_DEGREE_GRADUATION_PROCESS_SINGLE = {
  STUDENTS: '/maintainers-portal/interim-degree-graduation-process/single',
  FAILED_DETAILS: ({
    studyPlanEnrollmentId,
    interimDegreeId,
  }: {
    studyPlanEnrollmentId: number | string;
    interimDegreeId: number | string;
  }) =>
    `/maintainers-portal/interim-degree-graduation-process/single/failed/${studyPlanEnrollmentId}/${interimDegreeId}`,
  AUTHORIZE:
    '/maintainers-portal/interim-degree-graduation-process/single/authorize',
};

const INTERIM_DEGREE_GRADUATION_PROCESS_STUDENTS = {
  STUDENTS: '/maintainers-portal/interim-degree-graduation-process/students',
  STUDENTS_DOWNLOAD:
    '/maintainers-portal/interim-degree-graduation-process/students/download',
  FORM: '/maintainers-portal/interim-degree-graduation-process/students/form',
};

export {
  ACADEMIC_OFFERS_MAINTAINER,
  ACADEMIC_RECORD,
  AUTH,
  BASE,
  COURSE_REGISTRATION,
  CURRICULUM_PROGRESS,
  GRADES_ENTRY,
  GRADUATION_PROCESS_MASSIVE,
  GRADUATION_PROCESS_SINGLE,
  MAINTAINERPORTAL,
  ACADEMIC_STRUCTURE,
  ENROLLMENT_SOFT_CHANGE as MODIFY_ENROLLMENT,
  PACKAGES,
  PARAMETERS,
  PERIODS,
  POSTULANTS,
  POSTULATION,
  REPORTS,
  SECTION_ATTRIBUTES,
  SECTION_MAINTAINER,
  SETTINGS,
  STUDENTS_DEGREE,
  STUDENTS_DEGREE_STUDENT,
  STUDENTS_POST_CLOSE,
  STUDENT_COURSE_VALIDATION,
  STUDENT_PETITIONS,
  STUDENT_PETITION_DETAIL,
  STUDENT_STATUS,
  STUDY_PLANS_OFFER,
  TEACHER,
  TUITION_CONTINUITY_DOCUMENT_REVIEW,
  TUITION_CONTINUITY_PROCESS_MANAGEMENT,
  TUITION_CONTINUITY_TARIFF,
  TUITION_PROCESS,
  PAYMENT_HUB,
  USERS_AND_ROLES,
  ACADEMIC_OFFER_INTERIM_DEGREE,
  INTERIM_DEGREE_GRADUATION_PROCESS_MASSIVE,
  INTERIM_DEGREE_GRADUATION_PROCESS_SINGLE,
  INTERIM_DEGREE_GRADUATION_PROCESS_STUDENTS,
};
