import { SelectOptionType } from '@octano/global-ui';
import { parsePhoneNumber } from 'libphonenumber-js';
import { DefaultValues } from 'react-hook-form';
import { format as formatRut } from 'rut.js';

import {
  DataOwnSustainer,
  SustainerStepResponse,
} from '../../../../types/sustainerTypes';
import { getSelectedOption } from '../../../../utils/selectFormat';
import {
  RELATIONSHIP_OWN_SUSTAINER,
  maritalStatusOptions,
  relationshipOptions,
} from '../../../../utils/selectOptions';
import { FieldsSustainerForm } from './SustainerForm';

export const formatDefaultValues = (
  data: SustainerStepResponse,
  communeOptions: SelectOptionType[],
  regionOptions: SelectOptionType[],
  countryOptions: SelectOptionType[],
  nationalityOptions: SelectOptionType[],
): DefaultValues<FieldsSustainerForm> => {
  const { sustainer } = data;
  const selectedCommune = getSelectedOption(
    sustainer?.communeId,
    communeOptions,
  );

  let ownSustainer: boolean | undefined;
  if (data.sustainerPassportAvailable) {
    ownSustainer = data.sustainer?.rut === data.student.rut ? true : undefined;
  } else {
    ownSustainer = undefined;
  }

  let hasPassport: boolean | undefined = false;
  if (data.sustainerPassportAvailable) {
    hasPassport = Boolean(
      sustainer?.passportNumber && sustainer.passportNumber !== '',
    );
  }

  const parsedPhone = sustainer?.phone
    ? parsePhoneNumber(sustainer?.phone)
    : null;
  const parsedCellPhone = sustainer?.cellPhone
    ? parsePhoneNumber(sustainer?.cellPhone)
    : null;

  return {
    hasPassport,
    relationship: relationshipOptions.find(
      (row) => row.value === sustainer?.relationshipId,
    ),
    occupation: sustainer?.occupation,
    names: sustainer?.names,
    paternalLastName: sustainer?.paternalLastName,
    maternalLastName: sustainer?.maternalLastName,
    rut: sustainer?.rut ? formatRut(sustainer.rut) : '',
    passportCountry: getSelectedOption(
      sustainer?.passportCountry,
      countryOptions,
    ),
    passportNumber: sustainer?.passportNumber,
    addressStreet: sustainer?.addressStreet,
    addressNumber: sustainer?.addressNumber,
    addressExtra: sustainer?.addressExtra,
    commune: selectedCommune,
    region: getSelectedOption(selectedCommune?.regionId, regionOptions),
    phone: parsedPhone?.isValid() ? parsedPhone?.formatInternational() : '',
    cellPhone: parsedCellPhone?.isValid()
      ? parsedCellPhone?.formatInternational()
      : '',
    email: sustainer?.email,
    ownSustainer,
    maritalStatus: getSelectedOption(
      sustainer?.maritalStatusId,
      maritalStatusOptions,
    ),
    nationality: getSelectedOption(
      sustainer?.nationality.id,
      nationalityOptions,
    ),
  };
};

interface PropsFormatDataOwnSustainer {
  data: SustainerStepResponse;
  communeOptions: SelectOptionType[];
  regionOptions: SelectOptionType[];
  countryOptions: SelectOptionType[];
  cityOptions?: SelectOptionType[];
  nationalityOptions?: SelectOptionType[];
}

export const formatDataOwnSustainer = ({
  data,
  communeOptions,
  regionOptions,
  countryOptions,
  cityOptions,
  nationalityOptions,
}: PropsFormatDataOwnSustainer): DataOwnSustainer => {
  const { student } = data;
  const selectedCity = cityOptions
    ? getSelectedOption(student?.cityId, cityOptions)
    : undefined;
  const selectedCommune = getSelectedOption(student?.communeId, communeOptions);
  const selectedRegion = getSelectedOption(
    selectedCommune?.regionId,
    regionOptions,
  );
  const selectedMaritalStatus = maritalStatusOptions
    ? getSelectedOption(student.maritalStatusId, maritalStatusOptions)
    : undefined;
  const selectedNationality = nationalityOptions
    ? getSelectedOption(student?.nationality?.id, nationalityOptions)
    : undefined;

  const parsedPhone = student?.phone ? parsePhoneNumber(student?.phone) : null;
  const parsedCellPhone = student?.cellPhone
    ? parsePhoneNumber(student?.cellPhone)
    : null;

  return {
    relationship: relationshipOptions.find(
      (row) => row.value === RELATIONSHIP_OWN_SUSTAINER,
    ),
    names: student.names,
    paternalLastName: student.paternalLastName,
    maternalLastName: student.maternalLastName,
    rut: student.rut ? formatRut(student.rut) : undefined,
    passportCountry: getSelectedOption(
      student?.passportCountry,
      countryOptions,
    ),
    passportNumber: student.passportNumber,
    phone: parsedPhone?.isValid() ? parsedPhone?.formatInternational() : '',
    cellPhone: parsedCellPhone?.isValid()
      ? parsedCellPhone?.formatInternational()
      : '',
    email: student.email,
    addressStreet: student.addressStreet,
    addressNumber: student.addressNumber,
    addressExtra: student.addressExtra,
    country: getSelectedOption(student.countryId, countryOptions),
    customCity: student.customCity,
    location: student.location,
    commune: selectedCommune,
    region: selectedRegion,
    city: selectedCity,
    maritalStatus: selectedMaritalStatus,
    nationality: selectedNationality,
  };
};
