import { Button, TextInput } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { useValidations } from '../../../../../../hooks/useValidations';
import SendEmail from './SendEmail';

const fields = {
  email: 'email',
  phone: 'phone',
};

const CreateUserFormContactFields = ({
  prefix: subPrefix,
  passport,
  disabledInputs,
  isSubmitLoad,
}: {
  prefix: string;
  passport: boolean;
  disabledInputs?: boolean;
  isSubmitLoad?: boolean;
}) => {
  const { t } = useTranslation();
  const prefix = `${subPrefix}.contact`;
  const {
    validateTextNotEmpty,
    validatePhone,
    validateEmail,
  } = useValidations();

  const { control, formState } = useFormContext();
  const { isDirty, isValid } = formState;

  return (
    <Row>
      <Col md={4}>
        <TextInput
          name={`${fields.phone}`}
          control={control}
          formatter="phone"
          label={t(prefix + '.' + fields.phone)}
          placeholder={t('common.placeholder.cellphone')}
          rules={{
            validate: {
              notEmpty: validateTextNotEmpty,
              phone: validatePhone,
            },
          }}
        />
      </Col>
      <Col md={4}>
        <TextInput
          name={`${fields.email}`}
          control={control}
          label={t(prefix + '.' + fields.email)}
          rules={{
            validate: {
              notEmpty: validateTextNotEmpty,
              email: validateEmail,
            },
          }}
          tooltip={t(`${prefix}.tooltip.email`)}
        />
        <SendEmail />
      </Col>
      <Col xs={4}>
        <Button
          size="lg"
          type="submit"
          text={
            disabledInputs
              ? t(`${subPrefix}.btn.update`)
              : t(`${subPrefix}.btn.create`)
          }
          fullwidth
          disabled={!isValid || !isDirty}
          loading={isSubmitLoad}
        />
      </Col>
    </Row>
  );
};

export default CreateUserFormContactFields;
