import { Button } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { POSTULATION_STATUS } from '../../../../../types/postulationDetailStatus';
import { ActionForm } from '../../../../../types/tuitionProcessOnSite';
import { findIndexStatus } from '../helper/findIndexStatus';

interface ActionsForm {
  status: string;
  description: string;
  actions?: JSX.Element;
  disabledInputs?: boolean;
}

export const useActionForm = (
  admissionTypeId?: string,
  postulanDetailId?: number,
) => {
  const [submitLoad, setSubmitLoad] = useState<boolean>(false);
  const [modalConfirmOpen, setModalConfirmOpen] = useState<boolean>(false);
  const prefix = 'tuitionProcess.form.';
  const { t } = useTranslation();

  /**
   * Nota: fromStart to buttons's value define if it will show view
   * of study plan change or normal view to tuition process.
   */
  const getActionForm = useCallback(
    (
      status: string,
      onConfirmRetract?: () => void,
      onChangeEnrolledStudyPlan?: () => void,
      goToPostulation?: () => void,
      desistStudyPlanChange?: () => void,
    ): ActionsForm => {
      let waitingListActions: ActionForm[] = [];
      let onProcessActions: ActionForm[] = [];
      let enrolledActions: ActionForm[] = [];
      let changeStudyPlan: ActionForm[] = [];
      if (admissionTypeId !== 'CENTRALIZADA') {
        waitingListActions.push({
          type: 'button',
          text: t(prefix + 'actions.desisted'),
          outlined: true,
          color: 'danger',
          onClick: () => {
            setModalConfirmOpen(true);
          },
        });
        if (!postulanDetailId) {
          waitingListActions.push({
            type: 'submit',
            text: t(prefix + 'actions.savedDataAndChangePlanStudent'),
            outlined: true,
            color: 'primary',
            loading: submitLoad,
            value: JSON.stringify({ fromStart: true }),
          });
          waitingListActions.push({
            type: 'submit',
            text: t(prefix + 'actions.saveDataAndAvancedWaiting'),
            color: 'primary',
            value: JSON.stringify({ fromStart: false }),
          });
        } else {
          waitingListActions.push({
            type: 'submit',
            text: t(prefix + 'actions.savedDataAndChangePlanStudent'),
            outlined: true,
            color: 'primary',
            loading: submitLoad,
            value: JSON.stringify({ postulanDetailId, fromStart: true }),
          });
          waitingListActions.push({
            type: 'submit',
            text: t(prefix + 'actions.saveDataAndAvancedWaiting'),
            color: 'primary',
            value: JSON.stringify({ postulanDetailId, fromStart: false }),
          });
        }

        onProcessActions.push({
          type: 'button',
          text: t(prefix + 'actions.desisted'),
          outlined: true,
          color: 'danger',
          onClick: () => setModalConfirmOpen(true),
        });
        if (!postulanDetailId) {
          onProcessActions.push({
            type: 'submit',
            text: t(prefix + 'actions.savedDataAndChangePlanStudent'),
            outlined: true,
            color: 'primary',
            loading: submitLoad,
            value: JSON.stringify({ fromStart: true }),
          });
        } else {
          onProcessActions.push({
            type: 'submit',
            text: t(prefix + 'actions.savedDataAndChangePlanStudent'),
            outlined: true,
            color: 'primary',
            loading: submitLoad,
            value: JSON.stringify({ postulanDetailId, fromStart: true }),
          });
        }
      }
      enrolledActions.push({
        type: 'button',
        text: t(prefix + 'actions.retract'),
        outlined: false,
        color: 'danger',
        onClick: onConfirmRetract,
      });
      enrolledActions.push({
        type: 'button',
        text: t(prefix + 'actions.changeStudyPlanEnrolled'),
        color: 'primary',
        value: 'changeStudyPlanEnrolled',
        onClick: onChangeEnrolledStudyPlan,
      });
      changeStudyPlan.push({
        type: 'button',
        text: t(prefix + 'actions.desistStudyPlanChange'),
        color: 'primary',
        outlined: true,
        onClick: desistStudyPlanChange,
      });
      changeStudyPlan.push({
        type: 'button',
        text: t(prefix + 'actions.continueStudyPlanChange'),
        color: 'primary',
        onClick: goToPostulation,
      });

      if (!postulanDetailId) {
        onProcessActions.push({
          type: 'submit',
          text: t(prefix + 'actions.saveDataAndStartEnrolled'),
          color: 'primary',
          value: JSON.stringify({ fromStart: false }),
        });
      } else {
        onProcessActions.push({
          type: 'submit',
          text: t(prefix + 'actions.saveDataAndStartEnrolled'),
          color: 'primary',
          value: JSON.stringify({ postulanDetailId, fromStart: false }),
        });
      }

      return {
        status: findIndexStatus(status, [
          t(prefix + 'state.body.inProcess'),
          t(prefix + 'state.body.waiting'),
          t(prefix + 'state.body.desisted'),
          t(prefix + 'state.body.enrolled'),
          t(prefix + 'state.body.changing'),
          t(prefix + 'state.body.notPostulate'),
        ]),
        description: findIndexStatus(status, [
          t(prefix + 'body.inProcess'),
          t(prefix + 'body.waiting'),
          t(prefix + 'body.desisted'),
          t(prefix + 'body.enrolled'),
          t(prefix + 'body.changing'),
          t(prefix + 'body.notPostulate'),
        ]),
        disabledInputs:
          status === POSTULATION_STATUS.ENROLLED ||
          status === POSTULATION_STATUS.CHANGE_STUDY_PLAN,
        actions: findIndexStatus(status, [
          // en_proceso
          addActionsForm(onProcessActions),
          // en_espera
          addActionsForm(waitingListActions),
          // desistido
          addActionsForm([
            {
              type: 'submit',
              text: t(prefix + 'goEnrolled'),
              color: 'primary',
              value: JSON.stringify({ fromStart: false }),
            },
          ]),
          // matriculado
          addActionsForm(enrolledActions),
          // en proceso de cambio de plan de estudio
          addActionsForm(changeStudyPlan),
          // default
          addActionsForm([
            {
              type: 'submit',
              text: t(prefix + 'goEnrolled'),
              color: 'primary',
              value: JSON.stringify({ fromStart: false }),
            },
          ]),
        ]),
      };
    },
    [admissionTypeId, postulanDetailId, submitLoad, t],
  );

  const addActionsForm = (actions: ActionForm[]) => {
    return (
      <Row>
        {actions.length !== 3 && <Col md={8 / actions.length} />}
        {actions.map((arg: ActionForm, key) => {
          let { type, outlined, color, text, onClick, loading, value } = arg;
          return (
            <Col md={4} key={key}>
              <Button
                style={{ height: '100%' }}
                type={type}
                text={text}
                size="sm"
                fullwidth
                outlined={outlined}
                color={color}
                onClick={onClick}
                loading={loading}
                value={value}
              />
            </Col>
          );
        })}
      </Row>
    );
  };

  return {
    getActionForm,
    modalConfirmOpen,
    setModalConfirmOpen,
    submitLoad,
    setSubmitLoad,
  };
};
