import request from '../../../api/request';
import { Period } from '../../../api/requests/MantainerPortal';
import { AcademicOffersTypeDuplicate as AcademicOffers } from '../../../types/AcademicOffersType';
import { PaginationRequestType } from '../../../types/paginationRequestType';
import { TuitionItem, TuitionListResponse } from '../../../types/periodTuition';
import { StudyPlanVersion } from '../../../types/studyPlan';

const TUITION = {
  PERIODS: '/maintainers-portal/admission-and-enrollments/tuitions/periods',
  TUITION_PERIODS:
    '/maintainers-portal/admission-and-enrollments/tuitions/tuition-periods',
};

/**
 * Obtiene el listado de periodos sin valores de matriculas
 */
export function getPeriod() {
  return request<Period[]>(TUITION.PERIODS, {
    method: 'get',
  });
}

/**
 * Obtiene el listado de matrículas con paginación
 */
export const getTuitionRequest = (items: number = 10, page: number = 0) => {
  const url = `${TUITION.TUITION_PERIODS}`;
  return request<TuitionListResponse>(url, {
    params: { items_per_page: items, page },
  });
};

/**
 * Crear/editar una matrícula
 */
export const saveTuitionRequest = (data: any) => {
  const editing = !!data.id;
  const url = `${TUITION.TUITION_PERIODS}/${editing ? data.id : ''}`;
  return request<TuitionItem>(url, {
    method: editing ? 'put' : 'post',
    data,
  });
};

/**
 * DISCOUNTS
 */
const DISCOUNTS = {
  DELETE: (discountId: number) =>
    `/maintainers-portal/admission-and-enrollments/discounts/${discountId}`,
  DISCOUNTS: '/maintainers-portal/admission-and-enrollments/discounts',
  SEARCH_DISCOUNTS:
    '/maintainers-portal/admission-and-enrollments/discounts/search',
};

type Modify<T, R> = Omit<T, keyof R> & R;

export interface Discount {
  id?: number;
  code: string;
  name: string;
  description: string | null;
  percentage: number;
}

export interface DiscountFields
  extends Modify<Discount, { percentage: string }> {}

export type DiscountsListResponse = PaginationRequestType<Discount>;

/**
 *Obtiene el listado de todos los descuentos
 */
export const getAllDiscounts = () => {
  const url = `${DISCOUNTS.DISCOUNTS}`;
  return request<Discount[]>(url);
};

/**
 * Obtiene el listado de descuentos con paginación
 */
export const getDiscountsRequest = (items: number = 10, page: number = 0) => {
  return request<DiscountsListResponse>(DISCOUNTS.SEARCH_DISCOUNTS, {
    params: { items_per_page: items, page },
  });
};

export const saveDiscountRequest = (data: Discount) => {
  const editing = !!data.id;
  return request<Discount>(`${DISCOUNTS.DISCOUNTS}/${editing ? data.id : ''}`, {
    method: editing ? 'put' : 'post',
    data,
  });
};

export const deleteDiscountRequest = (discountId: number) => {
  return request<Discount>(DISCOUNTS.DELETE(discountId), {
    method: 'delete',
  });
};

/**
 * TARIFFS
 */

/**
 * Obtiene el listado de todas las ofertas academicas
 */
const TARIFF = {
  ACADEDMIC_OFFERS:
    '/maintainers-portal/admission-and-enrollments/tariffs/academic-offers',
  GET_VERSIONS_BY_ACADEMIC_OFFER:
    '/maintainers-portal/admission-and-enrollments/tariffs/academic-offers/versions',
  TARIFF: '/tariff',
  ALL_TARIFF: '/tariff/all',
};

export const getAllAcademicOffersRequest = () => {
  const url = `${TARIFF.ACADEDMIC_OFFERS}`;
  return request<PaginationRequestType<AcademicOffers>>(url);
};

/**
 * Obtiene el listado de todas las ofertas academicas
 */
export const getAcademicOffersTariffRequest = (
  academicOfferId: number,
  items: number = 10,
  page: number = 0,
) => {
  return request<PaginationRequestType<StudyPlanVersion>>(
    TARIFF.GET_VERSIONS_BY_ACADEMIC_OFFER,
    { params: { items_per_page: items, page: page - 1, academicOfferId } },
  );
};

/**
 * Crear/editar una matrícula
 */
export const saveTariffRequest = (data: any) => {
  const url = `${TARIFF.ACADEDMIC_OFFERS}`;
  return request(url, {
    method: 'post',
    data,
  });
};
