import { PaginationRequestType } from '../../types/paginationRequestType';
import { DiscountTypes } from '../../views/AdmissionAndEnrollments/TariffAndTuition/Discount/parts/DiscountForm';
import { STUDY_PLANS_OFFER } from '../endpoints';
import request from '../request';

export type StudyPlanOfferItem = {
  Postulant_rut: string | null;
  Postulant_id: number;
  PostulantPassport_number: string | null;
  Postulant_names: string;
  Postulant_paternalLastName: string;
  Postulant_maternalLastName: string;
  Contact_cellPhone: string;
  Contact_email: string;
  Discount_discountValue: number | null;
  Details_enrolledDate: string | null;
  Details_retractedDate: string | null;
  Details_postulationId: number;
  Details_id: number;
  Discount_discountCode: string | null;
  Discount_discountName: string | null;
  Discount_id: number | null;
  PostulantPassport_countryId: string | null;
};

export type StudyPlanOffertResponse = PaginationRequestType<StudyPlanOfferItem>;

export type StudyPlanOffertOnlyOneResponse = {
  data: Omit<StudyPlanOfferItem, 'Postulant_id'>;
};

export type Discount = {
  postulationDetailId: number;
  discountCode: string;
  discountName: string;
  discountValue: number;
  id: number;
  createdAt: string;
  updatedAt: string;
};

export interface StudyPlansOfferDocumentationReview {
  StudyPlanVersionOffer_id: number;
  StudyPlanVersion_name: string;
  AcademicOfferStudyPlanVersionOffers_id: number;
  Admissions_id: number;
  Campus_name: string;
  Schedule_name: string;
  enrolled: number;
  notReviewed: number;
  waiting: number;
  approved: number;
  approvedPercentage: number;
  StudyPlan_name: string;
}

export interface AcademicOfferStudyPlansOfferDocumentationByStatus {
  Postulant_id: number;
  Postulant_rut?: string;
  Postulant_names: string;
  Postulant_paternalLastName: string;
  Postulant_maternalLastName: string;
  Postulant_paternalPreference: boolean;
  Postulant_birthday: string;
  Contact_cellPhone: string;
  Contact_email: string;
  PostulantPassport_number?: string;
  PostulantPassport_countryId?: string;
  Details_id: number;
}

export interface SearchStudyPlansOfferDocumentationByStatus
  extends AcademicOfferStudyPlansOfferDocumentationByStatus {
  tab: DocumentationReviewStatus;
}

export interface StudyPlansOfferCampusSchedule {
  StudyPlanVersionOffer_id: number;
  StudyPlanVersion_name: string;
  Campus_name: string;
  Schedule_name: string;
  StudyPlan_name: string;
}

export interface PostulantDiscounts {
  id?: number;
  discountName: string;
  discountCode: string;
  discountValue: number;
  amountWithDiscount: number;
  type: DiscountTypes;
  postulationDetailId: number;
}

/**
 * Cambiar postulante a desistido. se usa en matricula presencial. mover endpoint!
 */
export const changeToDesist = (id: number) => {
  const url = `${STUDY_PLANS_OFFER.TO_STATUS_DESISTED}/${id}`;
  return request<StudyPlanOfferItem>(url, {
    method: 'PUT',
  });
};

/**
 * Se usa en matricula presencial. mover endpoint!
 */
export const changeStatusToRetracted = (
  id: number,
  data: { showWarning: boolean },
) => {
  const url = `${STUDY_PLANS_OFFER.TO_STATUS_RETRACTED}/${id}`;
  return request<StudyPlanOffertResponse>(url, {
    method: 'PUT',
    data,
  });
};

export enum DocumentationReviewStatus {
  NOT_REVIEWED = 'sin_revision',
  WAITING = 'en_espera',
  APPROVED = 'aprobado',
}
