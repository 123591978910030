import { Button, CheckInput, Icon, Modal } from '@octano/global-ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { changeStatusToRetracted } from '../../api/requests/studyPlansOffer';
import { showToast } from '../../utils/toast';
import CardTitle from '../text/CardTitle';

interface ModalConfirmRetractProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  postulationDetailId: number;
  student?: string;
}

export const ModalConfirmRetract = ({
  open,
  onClose,
  onConfirm,
  student,
  postulationDetailId,
}: ModalConfirmRetractProps): JSX.Element => {
  const { t } = useTranslation();

  const [check, setCheck] = useState<boolean>(false);

  const onSave = useCallback(async () => {
    let { error } = await changeStatusToRetracted(postulationDetailId ?? 0, {
      showWarning: false,
    });
    showToast(Boolean(error), t);
    if (!error) {
      onConfirm();
      onClose();
    }
  }, [postulationDetailId, t, onConfirm, onClose]);

  function onCheck(e: any) {
    setCheck(e.target.checked);
  }

  return (
    <Modal isOpen={open} size="md" toggle={onClose}>
      <Row>
        <Col>
          <div className="text-center">
            <Icon color="secondary" name="warning" size="70px" />
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <div className="text-center">
            <CardTitle children={t('admission.modalConfirmation.title')} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="text-center">
            <p className="fs-16 text-light">
              {t('admission.modalConfirmation.body')}
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <span>{t('admission.modalConfirmation.student')}</span>
          <div className="student-content">
            <ul className="mb-5 mt-2">
              <li className="text-dark">{student}</li>
            </ul>
          </div>
        </Col>
      </Row>
      <div className="mt-4">
        <CheckInput
          name="hasMentions"
          value={check}
          onChange={onCheck}
          label={t('admission.modalConfirmation.check')}
        />
      </div>
      <Row className="pt-2">
        <Col
          xs={{ order: 2, size: 12 }}
          md={{ order: 1, size: 6 }}
          className="ml-auto"
        >
          <div>
            <Button
              type="button"
              text={t(`common.actions.cancel`)}
              onClick={onClose}
              outlined
              fullwidth
            />
          </div>
        </Col>
        <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 6 }}>
          <div className="pb-3 pb-md-0">
            <Button
              type="button"
              text={t('common.actions.confirm')}
              onClick={onSave}
              fullwidth
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};
