import {
  Button,
  Modal,
  OutlinedSelect,
  OutlinedSelectOptionType,
  useMobile,
} from '@octano/global-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import {
  Period,
  getPeriodOFMantainerPortal,
} from '../../../api/requests/MantainerPortal';
import { useLoadingState } from '../../../hooks/useLoadingState';
import GeneratingFileModal from './GeneratingFileModal';
import NoResultsModal from './NoResultsModal';

interface Props {
  isOpen: boolean;
  modalTitle: string;
  onConfirm?: (periodId: number) => void;
  onCancel?: () => void;
}

export default function SelectPeriodModal({
  isOpen,
  modalTitle,
  onConfirm = () => null,
  onCancel = () => null,
}: Props) {
  const prefix = 'reports.sectionGrades.datesConfig';

  const isMobile = useMobile();
  const { t } = useTranslation();

  const {
    loading,
    setLoading,
    errorLoading,
    setErrorLoading,
  } = useLoadingState();

  const [periods, setPeriods] = useState<Period[]>([]);
  const [currentPeriod, setCurrentPeriod] = useState<Period>();

  const getPeriods = useCallback(async () => {
    setLoading(true);
    const { data, error } = await getPeriodOFMantainerPortal();
    if (error) {
      setErrorLoading(error.code);
    } else if (data) {
      setErrorLoading(undefined);
      setPeriods(data.periods);
      setCurrentPeriod(data.currentPeriod);
    }
    setLoading(false);
  }, [setLoading, setErrorLoading]);

  const changePeriod = useCallback(
    (option: OutlinedSelectOptionType) => {
      const period = periods.find(({ id }) => option.value === id) as Period;
      setCurrentPeriod(period);
    },
    [periods],
  );

  useEffect(() => {
    if (isOpen) getPeriods();
  }, [getPeriods, isOpen]);

  const options = useMemo<OutlinedSelectOptionType[]>(() => {
    return periods.map(({ name, id }) => {
      return {
        label: name,
        value: id,
      };
    });
  }, [periods]);

  const periodSelected = useMemo(() => {
    return options.find(({ value }) => currentPeriod?.id === value);
  }, [options, currentPeriod]);

  if (loading && isOpen) {
    return <GeneratingFileModal isOpen />;
  }

  if (errorLoading && isOpen) {
    return (
      <NoResultsModal
        isOpen
        onConfirm={onCancel}
        texts={{
          title: t('reports.sectionGrades.noResults.title'),
          description: t('reports.sectionGrades.noResults.description'),
        }}
      />
    );
  }

  return (
    <Modal isOpen={isOpen} toggle={() => null}>
      <div>
        <h1 className="text-dark text-center fw-600 fs-20">{modalTitle}</h1>
        <p className=" text-center fs-16 lh-30 mt-4 mb-5">
          {t(`${prefix}.subtitle`)}
        </p>
        <Row>
          <Col xs={12}>
            <OutlinedSelect
              name="periodId"
              label="Periodo"
              value={periodSelected}
              options={options}
              isClearable={false}
              onChange={changePeriod}
            />
          </Col>
        </Row>
        <div
          className="d-flex flex-wrap justify-content-between mt-5"
          style={{ gap: '1rem' }}
        >
          <Button
            text={t(`common.actions.cancel`)}
            outlined
            style={{ width: isMobile ? '100%' : 197 }}
            onClick={onCancel}
          />
          <Button
            text={t(`common.actions.download`)}
            style={{ width: isMobile ? '100%' : 197 }}
            onClick={() => onConfirm(periodSelected!.value as number)}
          />
        </div>
      </div>
    </Modal>
  );
}
