import { SidenavCategoryType, SidenavLinkType } from '@octano/global-ui';

import { PermissionName } from '../types/Auth';
import { SHOW_PAYMENT_HUB } from './constants';

export interface AllowedLink {
  disabled?: boolean;
}

export interface WithPermissions {
  requiredPermissions?: PermissionName[];
  allPermisionsRequired?: boolean;
}

export type LinkWithPermissions = SidenavLinkType &
  WithPermissions &
  AllowedLink;

export type SubLinkWithPermissions = SidenavCategoryType['links'][number] &
  WithPermissions &
  AllowedLink;

export type CategoryWithPermissions = Omit<SidenavCategoryType, 'links'> & {
  links: SubLinkWithPermissions[];
};

export type SidenavLinkWithPermission =
  | LinkWithPermissions
  | CategoryWithPermissions;

const purgeLinks = (
  links: SidenavLinkWithPermission[],
): SidenavLinkWithPermission[] => {
  links = links.filter((mainLink: any) => {
    return !mainLink?.disabled;
  });
  links = links.map((mainLink: any) => ({
    ...mainLink,
    links: mainLink?.links?.filter((subLink: any) => !subLink?.disabled),
  }));
  return links;
};

export const SIDENAV_LINKS: SidenavLinkWithPermission[] = purgeLinks([
  {
    icon: 'maintainer',
    name: 'Estructura Académica',
    links: [
      {
        name: 'Periodos',
        path: '/maintainers/periods',
        requiredPermissions: [PermissionName.ACADEMIC_STRUCTURE],
      },
      {
        name: 'Sedes',
        path: '/maintainers/campus',
        requiredPermissions: [PermissionName.ACADEMIC_STRUCTURE],
      },
      {
        name: 'Facultades',
        path: '/maintainers/schools',
        requiredPermissions: [PermissionName.ACADEMIC_STRUCTURE],
      },
      {
        name: 'Jornadas',
        path: '/maintainers/schedules',
        requiredPermissions: [PermissionName.ACADEMIC_STRUCTURE],
      },
      {
        name: 'Modalidades',
        path: '/maintainers/modalities',
        requiredPermissions: [PermissionName.ACADEMIC_STRUCTURE],
      },
    ],
  },
  {
    icon: 'academic_offer',
    name: 'Oferta Académica',
    links: [
      {
        name: 'Asignaturas de malla curricular',
        path: '/academic-offers/curricular-subjects',
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_CURRICULAR_SUBJECT],
      },
      {
        name: 'Planes de estudio',
        path: '/academic-offers/study-plans',
        allPermisionsRequired: true,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_STUDY_PLAN],
      },
      {
        name: 'Títulos intermedios',
        path: '/academic-offers/interim-degree/curriculums',
        requiredPermissions: [
          PermissionName.ACADEMIC_OFFER_INTERIM_DEGREE_LIST,
        ],
      },
      {
        name: 'Oferta de vacantes',
        path: '/academic-offers/vacant-offers',
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_VACANT_OFFER],
      },
    ],
  },
  {
    icon: 'admision',
    name: 'Admisión y Matrícula',
    links: [
      {
        name: 'Aranceles y descuentos',
        path: '/tariff-tuiton',
        requiredPermissions: [
          PermissionName.ADMISSIONS_AND_ENROLLMENTS_TARIFFS_AND_DISCOUNTS,
        ],
      },
      {
        name: 'Matrícula presencial',
        path: '/tuition-process',
        requiredPermissions: [
          PermissionName.ADMISSIONS_AND_ENROLLMENTS_TUITION_PROCESS,
        ],
      },
      {
        name: 'Gestión de admisión',
        path: '/admissions',
        requiredPermissions: [
          PermissionName.ADMISSIONS_AND_ENROLLMENTS_ADMISSION_MANAGEMENT,
        ],
      },
      {
        name: 'Revisión de documentación',
        path: '/documents',
        requiredPermissions: [
          PermissionName.ADMISSIONS_AND_ENROLLMENTS_DOCUMENTATION_REVIEW_PROCESS,
        ],
      },
    ],
  },
  {
    icon: 'tuiton_continuity',
    name: 'Matrícula de Continuidad',
    links: [
      {
        name: 'Aranceles y Matrícula',
        path: '/tuition-continuity-tariff-tuition',
        requiredPermissions: [
          PermissionName.TUITION_CONTINUITY_TARIFF_AND_TUITION,
        ],
      },
      {
        name: 'Gestión de Matrícula de Continuidad',
        path: '/tuition-continuity-process-management',
        requiredPermissions: [PermissionName.TUITION_CONTINUITY_MANAGEMENT],
      },
      {
        name: 'Revisión de documentación',
        path: '/tuition-continuity-document-review',
        requiredPermissions: [
          PermissionName.TUITION_CONTINUITY_DOCUMENT_REVIEW,
        ],
      },
    ],
  },
  {
    icon: 'packages',
    name: 'Secciones de asignaturas',
    links: [
      {
        name: 'Modificación de inscripción',
        path: '/course-registration',
        requiredPermissions: [PermissionName.ENROLLMENT_MODIFICATION],
      },
      {
        name: 'Modificación de secciones',
        path: '/sections-maintainer',
        requiredPermissions: [PermissionName.SECTIONS_MODIFICATION],
      },
      {
        name: 'Inscripción de paquetes',
        path: '/packages',
        requiredPermissions: [PermissionName.SECTIONS_PACKAGES_ALL],
      },
      {
        name: 'Ingreso de paquetes y secciones',
        path: '/packages-maintainer',
        requiredPermissions: [PermissionName.SECTION_PACKAGES_LOAD_ALL],
      },
      {
        name: 'Atributos de sección',
        path: '/section-attributes',
        requiredPermissions: [
          PermissionName.SHOW_SECTION_ATTRIBUTES_LIST,
          PermissionName.GET_SECTION_ATTRIBUTES,
          PermissionName.DOWNLOAD_SECTION_ATTRIBUTES_BASE_INFO,
        ],
      },
      {
        name: 'Cierre de sección',
        path: '/closing-period',
        requiredPermissions: [
          PermissionName.SHOW_LIST_PERIOD_CLOSE_ADMIN_PORTAL,
        ],
      },
      {
        name: 'Modificación de cierre',
        path: '/students-post-close',
        requiredPermissions: [PermissionName.STUDENTS_POST_CLOSE],
      },
    ],
  },
  {
    icon: 'identification',
    name: 'Herramientas de gestión',
    links: [
      {
        name: 'Activación docente',
        path: '/teacher-activation',
        requiredPermissions: [PermissionName.TEACHERS_ACTIVATION_ALL],
      },
      {
        name: 'Reportes',
        path: '/reports',
        requiredPermissions: [PermissionName.SHOW_REPORTS],
      },
      {
        name: 'Usuarios y Roles',
        path: '/users-and-roles',
        requiredPermissions: [PermissionName.MANAGEMENT_TOOLS_USERS_AND_ROLES],
      },
      //   name: 'Activación de administrativos',
      //   path: '/53',
      // },
      // {
      //   name: 'Gestión de permisos',
      //   path: '/54',
      // },
      // {
      //   name: 'Asistencia docente',
      //   path: '/55',
      // },
      // {
      //   name: 'Activación del estudiante',
      //   path: '/56',
      // },
    ],
  },
  {
    icon: 'person_record',
    name: 'Gestión del estudiante',
    links: [
      // {
      //   name: 'Expendiente del alumno',
      //   path: '/61',
      // },
      {
        name: 'Estado del alumno',
        path: '/student-status',
        requiredPermissions: [
          PermissionName.SHOW_STUDENT_STATUS,
          PermissionName.CHANGE_STUDENT_STATUS,
        ],
      },
      {
        name: 'Ficha del alumno',
        path: '/student-file',
        requiredPermissions: [
          PermissionName.STUDENT_MANAGEMENT_STUDENT_FILE_UPDATE,
        ],
      },
      {
        name: 'Avance Curricular',
        path: '/avance-curricular',
        requiredPermissions: [PermissionName.STUDENT_CURRICULUM_PROGRESS_LIST],
      },
      {
        name: 'Cambio de Plan de Estudio',
        path: '/enrollment-soft-change',
        requiredPermissions: [PermissionName.EDIT_CHANGE_STUDENTY_PLAN],
      },

      {
        name: 'Solicitudes',
        path: '/student-petitions',
        requiredPermissions: [PermissionName.STUDENTS_PETITIONS_LIST],
      },
      {
        name: 'Egresos Masivos',
        path: '/graduation-process-massive',
        requiredPermissions: [
          PermissionName.SHOW_GRADUATION_PROCESS_MASSIVE,
          PermissionName.LAUNCH_GRADUATION_PROCESS_MASSIVE,
        ],
      },
      {
        name: 'Egresos Individuales',
        path: '/graduation-process-single',
        requiredPermissions: [
          PermissionName.SEARCH_GRADUATE_GRADUATION_PROCESS_SINGLE,
          PermissionName.LAUNCH_GRADUATION_PROCESS_SINGLE,
        ],
      },
      {
        name: 'Lista de egresados',
        path: '/graduates-list',
        requiredPermissions: [PermissionName.SHOW_GRADUATES_LIST],
      },
      {
        name: 'Titulación',
        path: '/students-degree',
        requiredPermissions: [PermissionName.STUDENTS_DEGREE_LIST],
      },
    ],
  },
  {
    icon: 'certification',
    name: 'Títulos Intermedios',
    links: [
      {
        name: 'Habilitación Masiva',
        path: '/interim-degree-graduation-process/massive',
        requiredPermissions: [
          PermissionName.INTERIM_DEGREE_GRADUATION_PROCESS_ALL,
        ],
      },
      {
        name: 'Habilitación Individual',
        path: '/interim-degree-graduation-process/single',
        requiredPermissions: [
          PermissionName.INTERIM_DEGREE_GRADUATION_PROCESS_ALL,
        ],
      },
      {
        name: 'Procesos de Titulación',
        path: '/interim-degree-graduation-process/students',
        requiredPermissions: [
          PermissionName.INTERIM_DEGREE_GRADUATION_PROCESS_ALL,
        ],
      },
    ],
  },
  {
    icon: 'course_validation',
    name: 'Convalidación',
    links: [
      {
        name: 'Convalidación Interna',
        path: '/convalidacion-interna',
        requiredPermissions: [
          PermissionName.STUDENT_COURSE_VALIDATION_INTERNAL_LIST,
        ],
      },
    ],
  },
  {
    icon: 'pay_module',
    name: 'Centro de pagos',
    path: '/centro-pagos',
    disabled: !SHOW_PAYMENT_HUB,
    requiredPermissions: [PermissionName.SHOW_PAYMENT_HUB],
  },
  {
    icon: 'power',
    name: 'Carga Inicial',
    links: [
      {
        name: 'Record Académico',
        path: '/record-academico',
        requiredPermissions: [PermissionName.ACADEMIC_RECORD_LOAD],
      },
    ],
  },
  {
    icon: 'settings',
    name: 'Configuración general de parámetros',
    links: [
      // {
      //   name: 'Demográficos',
      //   path: '/config?tab=demographics',
      // },
      {
        name: 'Académicos',
        path: '/config?tab=academics',
        requiredPermissions: [
          PermissionName.UPDATE_SETTINGS_TIME_LESSON,
          PermissionName.CREATE_SETTINGS_GRADE_ENTRY,
        ],
      },
      // {
      //   name: 'Generales',
      //   path: '/config?tab=general',
      // },
      {
        name: 'Financieros',
        path: '/config?tab=finantial',
        requiredPermissions: [PermissionName.UPDATE_SETTINGS_FINANTIAL],
      },
      // {
      //   name: 'Centro de ayuda',
      //   path: '/config?tab=helpCenter',
      // },
    ],
  },
]);
