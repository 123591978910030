import { STUDENT_COURSE_VALIDATION } from '../../api/endpoints';
import request from '../../api/request';
import {
  CourseValidationInternalBase,
  CourseValidationInternalStudents,
  CourseValidationInternalStudentsQuery,
} from './types';

export function getCourseValidationInternal() {
  return request<CourseValidationInternalBase>(
    STUDENT_COURSE_VALIDATION.INTERNAL.BASE,
    {
      method: 'get',
    },
  );
}

export function getCourseValidationInternalStudents(
  query: CourseValidationInternalStudentsQuery,
) {
  return request<CourseValidationInternalStudents>(
    STUDENT_COURSE_VALIDATION.INTERNAL.STUDENTS,
    {
      method: 'get',
      params: {
        studyPlanVersionId: query.search.studyPlanVersionId,
        searchText: query.search.searchText,
        periodId: query.search.periodId,
        page: query.page - 1,
      },
    },
  );
}
