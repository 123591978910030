import { ColumnTable, Icon, Table } from '@octano/global-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PackagesViewsSection,
  SectionScheduleType,
} from '../../types/packageTypes';
import { SectionsScheduleDays } from '../../types/sectionSchedules';
import PackagesViewSectionsDetails from './PackagesViewSectionsDetails';

export interface PackageViewSection {
  sectionName: string;
  courseName: string;
  teachersNames: string[];
  sectionSchedules: {
    day: SectionsScheduleDays;
    startTime: string;
    endTime: string;
    classroomName: string;
  }[];
}

export interface PackagesViewSectionsProps {
  loading?: boolean;
  data: PackagesViewsSection[] | null;
}

export default function PackagesViewSections({
  data,
  loading = false,
}: PackagesViewSectionsProps) {
  const { t } = useTranslation();
  const prefix = 'packages.view.sections';

  const [section, setSection] = useState<PackageViewSection | null>(null);

  const coursesColumns: ColumnTable<PackageViewSection>[] = [
    {
      columnName: 'sectionName',
      headerText: t(`${prefix}.section`),
    },
    {
      columnName: 'courseName',
      headerText: t(`${prefix}.course`),
    },
    {
      columnName: 'teachersNames',
      headerText: t(`${prefix}.professor`),
      cellFormat: ({ row }) => <>{[...row.teachersNames].sort().join(', ')}</>,
    },
    {
      columnName: 'id',
      headerText: '',
      cellFormat: ({ row }) => (
        <div
          className="cursor-pointer text-right"
          onClick={() => {
            setSection(row);
          }}
        >
          <Icon name="chevron_right" />
        </div>
      ),
    },
  ];

  return section ? (
    <PackagesViewSectionsDetails
      course={section}
      onBack={() => setSection(null)}
    />
  ) : (
    <div className="table-shadow">
      <Table
        borderless={false}
        striped={false}
        height={700}
        columns={coursesColumns}
        data={
          data?.map<PackageViewSection>((row) => ({
            sectionName: row.name,
            courseName: row.course.name,
            teachersNames: row.professors.map((p) => p.account.fullName),
            sectionSchedules: row.sectionSchedules
              .filter(
                (schedule) => schedule.type === SectionScheduleType.Default,
              )
              .map((s) => {
                return {
                  day: s.day,
                  startTime: s.startModule.startTime,
                  endTime: s.endModule.endTime,
                  classroomName: s.classroom?.name || '',
                };
              }),
          })) ?? []
        }
        isLoadingResults={loading}
      />
    </div>
  );
}
