import { Card, Col, Row } from 'reactstrap';
import { useState, useEffect, useCallback, useRef } from 'react';
import {
  createInterimDegree,
  getCurriculum,
} from '../../../api/requests/academicOfferInterimDegree';
import { Curriculum } from './interfaces/curriculum.interface';
import { useTranslation } from 'react-i18next';
import { addToast } from '@octano/global-ui';
import useUserState from '../../../hooks/useUserState';
import { PermissionName } from '../../../types/Auth';
import CurriculumBox from './parts/CurriculumBox';
import { useParams } from 'react-router-dom';
import GoBackToListButton from '../../../components/buttons/GoBackToListButton';
import DisplayPermissionError from '../../../components/info/DisplayPermissionError';
import InterimDegreeForm, {
  InterimDegreeFormFields,
} from './parts/InterimDegreeForm';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import InterimDegreeEmptyActivitiesModal, {
  InterimDegreeEmptyActivitiesModalMethods,
} from './parts/InterimDegreeEmptyActivitiesModal';

export const MODULE_PERMISSIONS = [
  PermissionName.ACADEMIC_OFFER_INTERIM_DEGREE_MANAGE,
];
const dictPrefix = 'interimDegree.create';

const InterimDegreeCreate = () => {
  const { t } = useTranslation();
  const { isAuthorizedTo } = useUserState();
  const history = useHistory();

  const emptyActivitiesRef =
    useRef<InterimDegreeEmptyActivitiesModalMethods>(null);

  const { curriculumId } = useParams<{ curriculumId: string }>();

  const [curriculum, setCurriculum] = useState<Curriculum>();
  const [detailLoaded, setDetailLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm<InterimDegreeFormFields>({
    defaultValues: {
      name: '',
      description: '',
      hasntActivities: false,
      semesterCoursesIds: [],
      activities: [],
    },
  });
  const { setError, handleSubmit } = methods;

  const handleDetails = useCallback(
    async (id: string | number) => {
      setLoading(true);
      const { data, error } = await getCurriculum(id, true);
      setLoading(false);

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        setCurriculum(data ?? undefined);
      }
    },
    [t],
  );

  const handleValidate = useCallback(
    (values: InterimDegreeFormFields) => {
      let hasErrors = false;
      if (!values?.name?.trim()) {
        setError('name', { message: t(`${dictPrefix}.nameIsRequired`) });
        hasErrors = true;
      } else if (!values?.semesterCoursesIds?.length) {
        setError('name', { message: t(`${dictPrefix}.courseIsRequired`) });
        hasErrors = true;
      }
      if (!values?.description?.trim()) {
        setError('description', {
          message: t(`${dictPrefix}.descriptionIsRequired`),
        });
        hasErrors = true;
      }
      if (hasErrors) {
        addToast({
          text: t(`${dictPrefix}.validationError`),
          color: 'danger',
          icon: 'error',
        });
      }
      return !hasErrors;
    },
    [t, setError],
  );

  const handleCreate = useCallback(
    async (values: InterimDegreeFormFields) => {
      if (!handleValidate(values) || loading) {
        return;
      }
      setLoading(true);
      const { error } = await createInterimDegree({
        ...values,
        hasActivities: !values?.hasntActivities,
        activities: values?.activities ?? [],
        curriculumId: Number(curriculumId),
      });
      setLoading(false);

      if (error) {
        addToast({
          text: t(`${dictPrefix}.errorOnCreate`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        addToast({
          text: t(`${dictPrefix}.successCreate`),
          color: 'success',
          icon: 'check',
        });
        history.goBack();
      }
    },
    [t, curriculumId, handleValidate, history, loading],
  );

  const handleRequestCreate = useCallback(
    async (values: InterimDegreeFormFields) => {
      if (!handleValidate(values) || loading) {
        return;
      }
      if (values?.activities?.length) {
        return handleCreate(values);
      } else {
        emptyActivitiesRef?.current?.open(values);
      }
    },
    [handleCreate, loading, handleValidate],
  );

  useEffect(() => {
    if (!detailLoaded && curriculumId) {
      handleDetails(curriculumId);
      setDetailLoaded(true);
    }
  }, [handleDetails, detailLoaded, curriculumId]);

  if (!isAuthorizedTo(MODULE_PERMISSIONS, true)) {
    return (
      <div className="w-full px-3">
        <DisplayPermissionError permissions={MODULE_PERMISSIONS} insideCard />
      </div>
    );
  }

  return (
    <>
      <Card className="p-4 mx-3">
        <Row>
          <Col className="mb-4" xs={12}>
            <div className="d-flex flex-wrap w-100 align-items-start justify-content-center">
              <GoBackToListButton text={t(`${dictPrefix}.back`)} />
              <div className="flex-fill px-2" />
              <CurriculumBox
                studyPlan={curriculum?.studyPlanVersion?.studyPlan?.name}
                studyPlanCode={curriculum?.studyPlanVersion?.studyPlan?.code}
                version={curriculum?.studyPlanVersion?.name}
                mention={!curriculum?.isPrimary ? curriculum?.name : '-'}
                school={curriculum?.studyPlanVersion?.studyPlan?.school?.name}
              />
            </div>
          </Col>
        </Row>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleRequestCreate)}>
            <InterimDegreeForm
              dictPrefix={dictPrefix}
              curriculum={curriculum}
              loading={loading}
            />
          </form>
        </FormProvider>
      </Card>
      <InterimDegreeEmptyActivitiesModal
        ref={emptyActivitiesRef}
        dictPrefix={`${dictPrefix}.emptyActivities`}
        onSave={handleCreate}
      />
    </>
  );
};

export default InterimDegreeCreate;
