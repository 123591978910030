import { Box, Button, Select } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Col, Row } from 'reactstrap';

import { useParameters } from '../../../../../../hooks/useParameters';

const fields = {
  role: 'role',
};

const CreateUserFormRolFields = ({
  prefix: subPrefix,
  passport,
  disabledInputs,
  isSubmitLoad,
}: {
  prefix: string;
  passport: boolean;
  disabledInputs?: boolean;
  isSubmitLoad?: boolean;
}) => {
  const history = useHistory();

  const { t } = useTranslation();
  const prefix = `${subPrefix}.role`;

  const { control, watch, formState } = useFormContext();
  const { roleOptions } = useParameters();
  const { isDirty, isValid } = formState;

  const role = watch(`${fields.role}`);
  return (
    <>
      <Row>
        <Col md={12}>
          <Select
            label={t(prefix + '.' + fields.role)}
            name={`${fields.role}`}
            control={control}
            isSearchable={true}
            options={roleOptions}
          />
        </Col>
        {role && (
          <Col md={12}>
            <Box
              variant="outlined"
              color="secondary"
              title={`Descripción de rol`}
              body={role?.description ?? '-'}
              fullwidth
              style={{ padding: '10px 15px' }}
            />
          </Col>
        )}
      </Row>
      <Row className="pb-2 pt-4">
        <Col className={'offset-md-6 offset-lg-6 pb-2'} xs={12} sm={12} md={3}>
          <Button
            onClick={() => history.goBack()}
            text={t(`${prefix}.cancel`)}
            outlined
            fullwidth
            disabled={isSubmitLoad}
          />
        </Col>
        <Col xs={12} sm={12} md={3}>
          <Button
            type="submit"
            text={t(`${prefix}.save`)}
            fullwidth
            loading={isSubmitLoad}
            disabled={!isValid || !isDirty}
          />
        </Col>
      </Row>
    </>
  );
};

export default CreateUserFormRolFields;
