import { SidenavLayout } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import AddemicLogoExpanded from '../assets/addemic/logo_expanded.svg';
import AddemicLogo from '../assets/addemic/logo_small.svg';
import { CampusFooter } from '../components/CampusFooter/CampusFooter';
import { TopBar } from '../components/TopBar/TopBar';
import { ConnectionError } from '../components/modals/ConnectionError';
import RoutesByLayout from '../components/routes/RoutesByLayout';
import { IS_ADDEMIC } from '../config/constants';
import {
  CategoryWithPermissions,
  LinkWithPermissions,
  SIDENAV_LINKS,
} from '../config/sidenav';
import useUserState from '../hooks/useUserState';
import LayoutProvider from '../providers/LayoutProvider';
import withProvider from '../providers/withProvider';

interface TopBarTitlesDict {
  [route: string]: string;
}

const TITLES_KEY_DICTIONARY: TopBarTitlesDict = {
  'default': '',
  '/academic-offers/curricular-subjects': 'coursesMaintainer',
  '/academic-offers/study-plans': 'studyPlans',
  '/academic-offers/interim-degree': 'interimDegree',
  '/academic-offers/vacant-offers': 'academicOffers',
  '/admissions': 'admissions',
  '/maintainers': 'maintainers',
  '/packages-maintainer': 'packagesMaintainer',
  '/course-registration': 'updateCourseRegistration',
  '/sections-maintainer': 'sectionsMaintainer',
  '/section-attributes': 'sectionAttributes',
  '/tariff-tuiton': 'tariffAndTuition',
  '/tuition-process': 'tuitionProcess',
  '/packages': 'packages',
  '/documents': 'documents',
  '/student-status': 'studentStatus',
  '/student-file': 'studentFile',
  '/avance-curricular': 'curriculumProgress',
  '/closing-period': 'closingPeriod',
  '/teacher-activation': 'teacherActivation',
  '/reports': 'reports',
  '/students-post-close': 'studentGradesClosure',
  '/config': 'configurations',
  '/graduation-process-massive': 'graduationProcessMassive',
  '/graduation-process-single': 'graduationProcessSingle',
  '/graduates-list': 'graduatesList',
  '/tuition-continuity-process-management':
    'tuitionContinuityProcessManagement',
  '/tuition-continuity-tariff-tuition': 'tcTariffAndTuition',
  '/tuition-continuity-document-review': 'tcDocumentReview',
  '/convalidacion-interna': 'courseValidationInternal',
  '/record-academico': 'academicRecord',
  '/centro-pagos': 'paymentHub',
  '/student-petitions': 'studentsPetitions',
  '/students-degree': 'studentsDegree',
  '/enrollment-soft-change': 'modifyEnrollment',
  '/users-and-roles': 'usersAndRoles',
  '/interim-degree-graduation-process/massive':
    'interimDegreeGraduationProcessMassive',
  '/interim-degree-graduation-process/students':
    'interimDegreeGraduationStudents',
  '/interim-degree-graduation-process/single':
    'interimDegreeGraduationProcessSingle',
  '/interim-degree-graduation-process/failed':
    'interimDegreeGraduationProcessSingle',
  '/interim-degree-graduation-process': 'interimDegreeGraduationProcessMassive',
};

function AuthLayout() {
  const { isAuthorizedTo } = useUserState();
  const { t } = useTranslation();
  const titlePrefix = 'views';

  // Verificamo si hay declarado un titulo exacto para esa ruta
  const exactMatch = useRouteMatch({
    path: Object.keys(TITLES_KEY_DICTIONARY),
    exact: true,
  });

  // Si no hay titulo para esa ruta exacta, usamos el del modulo en caso de existir
  const moduleMatch = useRouteMatch({
    path: Object.keys(TITLES_KEY_DICTIONARY),
  });

  // Si no hay un titulo registrado usamos el valor por defecto del diccionario
  const currentLocation = exactMatch?.path || moduleMatch?.path || 'default';

  const isCategory = (item: LinkWithPermissions | CategoryWithPermissions) => {
    return !!(item as CategoryWithPermissions).links;
  };

  const isAuthorizedLink = (link: LinkWithPermissions) => {
    if (!link.requiredPermissions?.length) return true;

    return isAuthorizedTo(link.requiredPermissions, link.allPermisionsRequired);
  };

  const getCategoryWithAuthorizedLinks = (
    category: CategoryWithPermissions,
  ) => {
    const authorizedLinks = category.links.filter(isAuthorizedLink);
    return { ...category, links: authorizedLinks };
  };

  const getItemWithoutPermissionsInfo = (
    item: LinkWithPermissions | CategoryWithPermissions,
  ) => {
    if (isCategory(item)) {
      const category = item as CategoryWithPermissions;
      const newLinks = category.links.map((link) => ({
        name: link.name,
        path: link.path,
      }));
      return { ...item, links: newLinks };
    } else {
      const link = item as LinkWithPermissions;
      return { name: link.name, path: link.path, icon: link.icon };
    }
  };

  const getSidenavAuthorizedLinks = () => {
    const filteredItems = SIDENAV_LINKS.reduce((list, item) => {
      if (isCategory(item)) {
        const categoryWithFilteredLinks = getCategoryWithAuthorizedLinks(
          item as CategoryWithPermissions,
        );

        if (!categoryWithFilteredLinks.links.length) return list;

        return [...list, categoryWithFilteredLinks];
      }

      if (isAuthorizedLink(item as LinkWithPermissions)) {
        return [...list, item];
      }

      return list;
    }, [] as (LinkWithPermissions | CategoryWithPermissions)[]);

    return filteredItems.map(getItemWithoutPermissionsInfo);
  };

  return (
    <SidenavLayout
      links={getSidenavAuthorizedLinks()}
      logo={IS_ADDEMIC ? ADDEMIC_LOGOS : CAMPUS_LOGOS}
    >
      <div className="d-flex flex-column h-100">
        {/* 
          Para algunas aplicaciones de la suite Global 3000 el titulo del topbar es unico por modulo y
          para otras es diferente por vista. Es por esto que se usa un diccionario de titulos por ruta.
          El valor a registrar es la key donde se encuentra el titulo en el diccionario
        */}
        <TopBar
          title={t(`${titlePrefix}.${TITLES_KEY_DICTIONARY[currentLocation]}`)}
        />
        <div style={{ flexGrow: 1 }}>
          <RoutesByLayout />
        </div>
        <CampusFooter />
      </div>
      <ConnectionError />
    </SidenavLayout>
  );
}

export const AuthorizedLayout = withProvider(AuthLayout, LayoutProvider);

const CAMPUS_LOGOS = {
  desktop: {
    src: '/tenant/logo_expanded.svg',
    fallbackSrc: '/tenant/logo_expanded.svg',
  },
  mobile: {
    src: '/tenant/logo_small.svg',
    fallbackSrc: '/tenant/logo_small.svg',
  },
};

const ADDEMIC_LOGOS = {
  desktop: {
    src: AddemicLogoExpanded,
    fallbackSrc: AddemicLogoExpanded,
  },
  mobile: {
    src: AddemicLogo,
    fallbackSrc: AddemicLogo,
  },
};
