import { TextInput, addToast, useDebounce } from '@octano/global-ui';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { updateTimeVariableConfig } from '../../../../api/requests/settings';
import useUserState from '../../../../hooks/useUserState';
import { PermissionName } from '../../../../types/Auth';
import useTimeVariableConfig from './useTimeVariableConfig';

// Tiempo en milisegundos en el que puede escribir el user sin que se dispare la actualizacion
const TIME_TOLERANCE = 2000;

// Tiempo maximo y minimo permitido (espejo con validacion de back)
const MIN_TIME = 0;
const MAX_TIME = 100;

export default function TimeVariableSection() {
  const { t } = useTranslation();
  const prefix = 'configurations.academics.timeVariable';

  const { isAuthorizedTo } = useUserState();

  const { isLoading, config, refetch } = useTimeVariableConfig();
  const [value, setValue] = useState<number>();
  const [error, setError] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const debouncedValue = useDebounce(value, TIME_TOLERANCE, error);
  const userMadeChanges = useMemo(() => config && config.value !== value, [
    value,
    config,
  ]);

  const updateVariable = useCallback(
    async (newValue: number) => {
      setIsUpdating(true);

      const { error: updateError } = await updateTimeVariableConfig(newValue);

      if (updateError) {
        addToast({
          icon: 'error',
          text: t(`${prefix}.errorSavingChanges`),
          color: 'danger',
        });
      } else {
        // Actualizamos config
        await refetch();

        addToast({
          icon: 'success',
          text: t(`${prefix}.changesSaved`),
          color: 'success',
        });
      }

      setIsUpdating(false);
    },
    [t, refetch],
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = +e.target.value;
    setValue(inputValue);

    if (isNaN(inputValue) || inputValue < MIN_TIME || inputValue > MAX_TIME) {
      setError(true);
    } else {
      setError(false);
    }
  };

  // Sincronizamos el valor del input con lo que recibimos del backend
  useEffect(() => {
    if (config) {
      setValue(config.value);
    }
  }, [config, setValue]);

  useEffect(() => {
    if (
      debouncedValue !== undefined && // primer debounce no ejecuta actualizacion
      userMadeChanges && // el usuario ha hecho cambios en el input
      debouncedValue === value // se cumple ventana de tiempo
    ) {
      updateVariable(debouncedValue);
    }
  }, [userMadeChanges, debouncedValue, updateVariable, value, t]);

  return (
    <>
      <h2 className="text-primary fs-18 fw-600 text-uppercase mb-3">
        {t(`${prefix}.title`)}
      </h2>
      <p className="fs-14 text-medium mb-3">{t(`${prefix}.description`)}</p>
      <TextInput
        name="timeVariable"
        label={t(`${prefix}.inputLabel`)}
        disabled={
          isLoading ||
          isUpdating ||
          !isAuthorizedTo([PermissionName.UPDATE_SETTINGS_TIME_LESSON])
        }
        value={value}
        onChange={handleChange}
        errorText={
          error
            ? t(`${prefix}.invalidInput`, { min: MIN_TIME, max: MAX_TIME })
            : undefined
        }
      />
    </>
  );
}
